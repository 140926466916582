export default function RankingIcon(props) {
    return <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M6.92969 1.98364C6.92969 1.43136 7.3774 0.983643 7.92969 0.983643H13.9297C14.482 0.983643 14.9297 1.43136 14.9297 1.98364V16.3836C14.9297 16.9359 14.482 17.3836 13.9297 17.3836H7.92969C7.3774 17.3836 6.92969 16.9359 6.92969 16.3836V1.98364ZM8.92969 2.98364V15.3836H12.9297V2.98364H8.92969Z"
              fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0.929688 7.49609C0.929688 6.94381 1.3774 6.49609 1.92969 6.49609H7.92969C8.48197 6.49609 8.92969 6.94381 8.92969 7.49609V16.3836C8.92969 16.9359 8.48197 17.3836 7.92969 17.3836H1.92969C1.3774 17.3836 0.929688 16.9359 0.929688 16.3836V7.49609ZM2.92969 8.49609V15.3836H6.92969V8.49609H2.92969Z"
              fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12.9297 9.9729C12.9297 9.42062 13.3774 8.9729 13.9297 8.9729H19.9297C20.482 8.9729 20.9297 9.42062 20.9297 9.9729V16.3838C20.9297 16.936 20.482 17.3838 19.9297 17.3838H13.9297C13.3774 17.3838 12.9297 16.936 12.9297 16.3838V9.9729ZM14.9297 10.9729V15.3838H18.9297V10.9729H14.9297Z"
              fill="white"/>
    </svg>


}