import {SingleEliminationBracket, Match, SVGViewer, createTheme} from '@g-loot/react-tournament-brackets';
import useWindowSize from "@g-loot/react-tournament-brackets/dist/cjs/hooks/use-window-size";
import {useEffect, useState} from "react";
import callAPI from "../../common/api";
import TennisBall from "../TenniBall";
import {Dropdown, Tab, Table, Tabs} from "react-bootstrap";

import './style.scss'
import {isMobile} from "react-device-detect";

export default function Brackets({tournament}) {

    const [draws, setDraws] = useState([]);
    const [componentDraws, setComponentDraws] = useState([]);
    const [selectedMatches, setSelectedMatches] = useState([]);
    const [loading, isLoading] = useState(false);

    useEffect(() => {
        if (tournament) {
            isLoading(true);
            callAPI({}, 'getTournaments', `/${tournament.id}/draws`).then((resp) => {
                setDraws(resp.result);
                isLoading(false);
            })
        }else{
            setDraws([])
            setComponentDraws([])
        }

    }, [tournament]);

    const getFinalName = (p) => {
        let finalName = `${p?.first_name} ${p?.last_name}`;
        if (finalName.indexOf('undefined') >= 0){
            finalName = 'TBA';
        }

        return finalName;
    }

    useEffect(() => {
        if (draws) {
            let tmpDraws = [];
            draws.map((d) => {
                let tmpD = {
                    label: d.event_type_description,
                    size: d.draw_size,
                    rounds: [],
                    matches: []
                }
                d.rounds.map((r) => {
                    let tmpR = {
                        name: r.name,
                        matches: []
                    };
                    r.matches.reverse().map((m, i) => {

                        let teams = {
                            a: {
                                isBye: m.is_bye && m.team_a.player_a === null && m.team_a.player_b === null,

                                a: {
                                    fullName: getFinalName(m.team_a?.player_a),
                                    picture_url: m.team_a?.player_a?.picture_url,
                                },
                                b: {
                                    fullName: getFinalName(m.team_a?.player_b),
                                    picture_url: m.team_a?.player_b?.picture_url
                                }
                            },
                            b: {
                                isBye: m.is_bye && m.team_b.player_a === null && m.team_b.player_b === null,
                                a: {
                                    fullName: getFinalName(m.team_b?.player_a),
                                    picture_url: m.team_b?.player_a?.picture_url
                                },
                                b: {
                                    fullName: getFinalName(m.team_b?.player_b),
                                    picture_url: m.team_b?.player_b?.picture_url
                                }
                            }
                        }


                        tmpR.matches.push({
                            "id": m.match_code,
                            // "name": m.match_title,
                            "nextMatchId": null,
                            "nextLooserMatchId": null,
                            "tournamentRoundText": r.name,
                            // "startTime": m.start_time,
                            "state": "SCORE_DONE",
                            "participants": [
                                {
                                    "id": m.team_a?.player_a?.id,
                                    "resultText": m.team_a.set_scores?.map((s) => { return s.score}).join(" "),
                                    "isWinner": m.team_a.winner,
                                    "status": "PLAYED",
                                    "name": `${teams.a.isBye? 'Bye': teams.a.a.fullName}`,
                                    "picture": teams.a.a.picture_url
                                },
                                {
                                    "id": m.team_b?.player_a?.id,
                                    "resultText": m.team_b.set_scores?.map((s) => { return s.score}).join(" "),
                                    "isWinner": m.team_b.winner,
                                    "status": "PLAYED",
                                    "name": `${teams.b.isBye? 'Bye': teams.b.a.fullName}`,
                                    "picture": teams.b.a.picture_url
                                }
                            ]
                        });
                        tmpR.matches = tmpR.matches.sort((a, b) =>  a.id.localeCompare(b.id));
                    })
                    tmpD.rounds.push(tmpR);
                });
                //Reverse since they are coming by order descending
                tmpD.rounds = tmpD.rounds.reverse();

                //Loop over the rounds and assign the nextmatchid
                tmpD.rounds.map((r, i) => {
                    r.matches.map((m, j) => {
                        if (i < tmpD.rounds.length - 1) {
                            tmpD.rounds[i].matches[j].nextMatchId = tmpD.rounds[i + 1].matches[Math.floor(j / 2)].id
                        }
                        tmpD.matches.push(tmpD.rounds[i].matches[j])
                    })
                });

                tmpDraws.push(tmpD);
            });
            setComponentDraws(tmpDraws);

        }else{
            setComponentDraws([])
        }
    }, [draws])

    useEffect(() => {
        if(componentDraws){
            document.querySelectorAll('.klQHSe').forEach((e) => {
                console.log(e.innerHTML);
                e.innerHTML = e.innerHTML.replace("Round ", "");
                e.innerHTML = e.innerHTML.replace("Round of ", "R");
            })
        }
    }, [componentDraws]);

    const [width, height] = useWindowSize();

    const WhiteTheme = createTheme({
        // width: {width},
        padding: '15rem',
        background: '#EEF8FF',
        textColor: {main: '#000000', highlighted: '#07090D', dark: '#3E414D'},
        matchBackground: {wonColor: '#EEF8FF', lostColor: '#EEF8FF'},
        score: {
            background: {wonColor: '#EEF8FF', lostColor: '#EEF8FF'},
            text: {highlightedWonColor: '#000', highlightedLostColor: '#000'},
        },
        border: {
            color: '#FFF',
            highlightedColor: '#FFF',
        },
        roundHeader: {backgroundColor: '#FFFFFF', fontColor: '#1160D6'},
        connectorColor: '#1160D6',
        connectorColorHighlight: '#1160D6',
        svgBackground: '#FAFAFA',
    });


    return (loading? (<TennisBall rotating={loading} text={`retreiving draws...`}/>) : (componentDraws && componentDraws.length > 0) ? (
            <div className={'bracketsCard my-5 '}>
                <Tabs
                    defaultActiveKey={componentDraws && componentDraws.length > 0 ?componentDraws[0].label: ''}
                    className="bracketsTabs"
                    fill
                >
                    {componentDraws.map((d) => {
                        return <Tab eventKey={d.label} title={d.label}>
                            <SingleEliminationBracket
                                matches={d.matches}
                                matchComponent={Match}
                                theme={WhiteTheme}
                                options={{
                                    style: {
                                        roundHeader: {
                                            backgroundColor: WhiteTheme.roundHeader.backgroundColor,
                                            border: 'solid 5px red',
                                            fontColor: WhiteTheme.roundHeader.fontColor,
                                        },
                                        connectorColor: WhiteTheme.connectorColor,
                                        connectorColorHighlight: WhiteTheme.connectorColorHighlight,
                                    },
                                }}
                                svgWrapper={({children, ...props}) => (
                                    <SVGViewer background={WhiteTheme.svgBackground}
                                               SVGBackground={WhiteTheme.svgBackground}
                                               width={isMobile? width : (width-200)} height={height} {...props}>
                                        {children}
                                    </SVGViewer>
                                )}
                            />
                        </Tab>
                    })}
                </Tabs>
            </div>
        )
        : <TennisBall text={`check back soon for <br/>draws updates`}/>)
}