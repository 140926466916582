import {Container, Row, Col} from "react-bootstrap";

import './style.scss'
import Withdrawal from "./pages/withdrawal";
import Contact from "./pages/contact";
import Privacy from "./pages/privacy";
import Impressum from "./pages/impressum";
import Terms from "./pages/terms";

function StaticPage() {

    const pathName = window.location.pathname.substring(1);

    const pagesMap = {
        'withdrawal':  <Withdrawal />,
        'contact':  <Contact />,
        'privacy':  <Privacy />,
        'impressum':  <Impressum />,
        'terms':  <Terms />,
    }

    return (
        <Container id={'staticPageBody'}>
            <Row>
                <Col className={'px-0'}>
                    {pagesMap[pathName]}
                </Col>
            </Row>
        </Container>
    )
}

export default StaticPage;