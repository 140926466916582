export default function Privacy() {

    return (
        <div className={'text-start'}>
            <p>
                <strong>Datenschutzerklärung </strong>
                <br/><br/>
                Diese Website sammelt einige personenbezogene Daten von ihren Nutzern.
                Kontaktinformationen: privacy-atp@cookies.digital
                Eigentümer und Verwalter der Daten: Cookies Factory s.r.l.
                E-Mail-Adresse des Eigentümers: finance@cookies.digital
                Personenbezogene Daten, die zu folgenden Zwecken und unter Verwendung der folgenden Dienste verarbeitet
                werden:
                <br/><br/>
                <strong>ATP Golden Pass. </strong>
                <br/><br/>
                Dieses Dokument informiert Benutzer über die Technologien die dieser Website helfen, die unten
                beschriebenen Zwecke zu erreichen. Solche Technologien ermöglichen es dem Eigentümer, auf
                Informationen zuzugreifen und diese zu speichern. (z. B. durch Verwendung eines Cookies) oder
                Verwendung von Ressourcen (z. B. durch Ausführen eines Skripts) auf dem Gerät eines Nutzers,
                während dieser mit dieser Website interagiert.<br/>
                <br/><br/>
                Alle diese Technologien werden in diesem Dokument als "Tracker" definiert-sofern kein Anlass für
                eine Differenzierung besteht.<br/>
                <br/><br/>
                Zum Beispiel, während Cookies sowohl auf Web- als auch auf mobilen Browsern verwendet werden
                können, es wäre ungenau, über Cookies im Zusammenhang mit mobilen Apps zu sprechen, da sie ein
                browserbasierter Tracker sind.<br/>
                Aus diesem Grund in diesem Dokument, der Begriff Cookies wird nur verwendet, wenn er speziell
                dazu bestimmt ist, diesen bestimmten Typ anzugeben
                Zwecke für die Tracker verwendet werden, kann die Zustimmung des Nutzers verlangen.
                Wird die Einwilligung erteilt, so kann sie jederzeit nach dem in diesem Dokument beschriebenen
                Verfahren frei widerrufen werden.<br/>
                Diese Website setzt anbietereigene Tracker (so genannte „first-party Tracker") und fremde Tracker
                im Zusammenhang mit Diensten von Drittanbietern ein (so genannte „third-party Tracker"). Sofern in
                diesem Dokument nicht anders angegeben, haben solche Dritte auch Zugriff auf die von ihnen
                verwalteten Tracker.<br/>
                Die Geltungsdauer und der Verfall von Cookies und anderen vergleichbaren Tracker hängen von der
                vom Anbieter oder vom jeweiligen Drittanbieter festgelegten Lebensdauer ab. Einige davon verfallen,
                sobald der Nutzer die Browsersitzung beendet.<br/>
                Zusätzlich zu dem, was in den Beschreibungen in jeder der folgenden Kategorien angegeben ist,
                Benutzer können genauere und aktualisierte Informationen zur Lebensdauerspezifikation finden, und
                alle anderen relevanten Informationen- wie die Präsenz von anderen Tracker in den verlinkten
                Datenschutzbestimmungen der jeweiligen Drittanbieter oder bei direkter Nachfrage beim Inhaber
                erhalten.<br/>
                Um mehr Informationen über kalifornische Verbraucher und ihre Datenschutzrechte zu erhalten,
                Nutzer können die Datenschutzerklärung lesen.
                Aktivitäten Notwendig für den Betrieb dieser Website und bei der Bereitstellung des Services
                Diese Website verwendet sogenannte „technische“ Cookies und ähnliche Tracker, um Vorgänge zu
                ermöglichen, die für die Durchführung und Erbringung des Dienstes erforderlich sind.<br/>
                Drittanbieter-Tracker<br/>
                Tag Management<br/>
                Sonstige Tätigkeiten im Zusammenhang mit der Nutzung von Tracker<br/>
                Messungen<br/>
                Diese Website verwendet Tracker, um den Datenverkehr zu messen und analysiert das<br/>
                Nutzerverhalten mit dem Ziel, den Service zu verbessern.<br/>
                Analysen<br/>
                Anonymisierten Webanalysedienst<br/>
                Wie Sie Präferenzen verwalten und Ihre Zustimmung erteilen oder widerrufen können
                Sie können Ihre „Cookie“-bezogenen Einstellungen verwalten, und Einwilligung verweigern und
                widerrufen, soweit relevant:<br/>
                Nutzer können Tracker-Einstellungen direkt über die eigenen Geräteinstellungen verwalten, indem
                sie z. B. dem Einsatz oder der Speicherung von Trackern gänzlich widersprechen.<br/>
                Soweit der Einsatz von Trackern auf einer Einwilligung beruht, können Nutzer außerdem die
                Einwilligung durch entsprechende Auswahl im Cookie Banner oder durch Änderung genannter
                Auswahl über das entsprechende Einwilligungs-Widget (sofern vorhanden) erteilen oder widerrufen
                Es ist auch möglich, über entsprechende Browser- oder Gerätefunktionen zuvor gespeicherte
                Identifikationen zu löschen, einschließlich derjenigen, die dazu dienen, die ursprüngliche Einwilligung
                des Nutzers zu speichern
                Andere Tracker, die im lokalen Speicher des Browsers abgelegt sind, können durch Löschen des
                Verlaufs entfernt werden.<br/>
                In Bezug auf Tracker von Drittanbietern, Benutzer können ihre Präferenzen verwalten und ihre
                Zustimmung über den entsprechenden Opt-out-Link widerrufen (soweit vorgesehen) , durch die
                angegebenen Verwendung in der Datenschutzrichtlinien des Drittanbieters oder durch
                Kontaktaufnahme mit dem Dritten.<br/>
                Einstellungen für die Lokalisierung des Trackers
                Der Nutzer kann, zum Beispiel, Informationen über die Verwaltung von Cookies in den
                meistgenutzten Browsern unter folgenden Adressen finden:<br/>
                Google Chrome<br/>
                Mozilla Firefox<br/>
                Apple Safari<br/>
                Microsoft Internet Explorer<br/>
                Microsoft Edge<br/>
                Brave<br/>
                Opera<br/>
                Benutzer können auch bestimmte Kategorien von Trackern verwalten, die in mobilen Apps, durch
                Opt-out über relevante Geräteeinstellungen verwendet werden, wie beispielweise die
                Benachrichtigungseinstellungen für Mobiltelefone oder Tracking-Einstellungen im Allgemeinen,
                (Benutzer können die Geräteeinstellungen öffnen und nach der entsprechenden Einstellung suchen).
                Konsequenzen für Die Verneinung der Einwilligung
                Der Nutzer kann frei entscheiden, ob er seine Einwilligung erteilt.<br/>
                Bitte beachten Sie jedoch, dass Tracker helfen dieser Website, den Benutzern eine bessere Erfahrung
                und erweiterte Funktionen zu bieten (in diesem Dokument genannten Zwecken zu dienen).<br/>
                Daher kann der Eigentümer ohne die Zustimmung des Benutzers möglicherweise in der Lage sein,
                verwandte Funktionen bereitzustellen.<br/><br/>

                Besitzer und Datakontrolleur<br/>
                Cookies Factory srl<br/>
                Via Cosseria 2 00192 Roma<br/>
                P.iva 12424661002<br/>
                Besitzer Kontakt email: privacy-atp@cookies.digital<br/><br/>

                Da der Einsatz von third-party Trackern durch diese Website vom Anbieter nicht vollständig
                kontrolliert werden kann, verstehen sich sämtliche Angaben zu third-party Trackern als Indikativ. Um
                vollständige Informationen zu erhalten, sind Nutzer gebeten, Einsicht in die in diesem Dokument
                aufgeführten Datenschutzerklärungen der jeweiligen Drittanbieter zu nehmen. Aufgrund der
                objektiven Komplexität von Tracking-Technologien, werden Nutzer aufgefordert, Kontakt zum
                Anbieter aufzunehmen, sofern sie weitere Informationen zum Einsatz solcher Technologien durch
                diese Website zu erhalten wünschen
                Definition und rechtliche Hinweise
                Personenbezogene Daten (oder Daten)
                Alle Informationen, durch die direkt oder in Verbindung mit weiteren Informationen -Beispiel einer
                persönlichen Identifikationsnummer- die Identität einer natürlichen Person bestimmt wird oder
                werden kann.<br/>
                Nutzungsdaten<br/>
                Informationen, die diese Website (oder Dienste Dritter, die diese Website in Anspruch nimmt),
                automatisch erhebt, z. B.: die IP-Adressen oder Domain-Namen der Computer von Nutzern, die diese
                Website verwenden, die URI-Adressen (Uniform Ressourcen Identifier), den Zeitpunkt der Anfrage,
                die Methode, die für die Übersendung der Anfrage an den Server verwendet wurde, die Größe der
                empfangenen Antwort-Datei, der Zahlencode, der den Status der Server-Antwort anzeigt
                (erfolgreiches Ergebnis, Fehler etc.), das Herkunftsland, die Funktionen des vom Nutzer verwendeten
                Browsers und Betriebssystems, die diversen Zeitangaben pro Aufruf (z. B. wie viel Zeit auf jeder Seite
                der Anwendung verbracht wurde) und Angaben über den Pfad, dem innerhalb einer Anwendung
                gefolgt wurde, insbesondere die Reihenfolge der besuchten Seiten, sowie sonstige Informationen
                über das Betriebssystem des Geräts und/oder die IT-Umgebung des Nutzers.<br/>
                Nutzer<br/>
                Die diese Website verwendende Person, die, soweit nicht anders bestimmt, mit dem Betroffenen
                übereinstimmt.<br/>
                Betroffener<br/>
                Die natürliche Person, auf die sich die personenbezogenen Daten beziehen.
                Auftrags Verarbeiter (oder Daten Verarbeiter)
                Natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene
                Daten im Auftrag des Verantwortlichen verarbeitet, wie in dieser Datenschutzerklärung beschrieben.<br/>
                Verantwortlicher (oder Anbieter, teilweise auch Eigentümer)
                Die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder
                gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung personenbezogener Daten
                sowie die hierfür verwendeten Mittel entscheidet, einschließlich der Sicherheitsmaßnahmen
                bezüglich des sich auf diese Website beziehenden Betriebs und der Nutzung. Soweit nichts anderes
                angegeben ist, ist der Verantwortliche die natürliche oder juristische Person, über welche diese
                Website angeboten wird.<br/>
                Diese Website (oder diese Anwendung)
                Das Hardware- oder Software-Tool, mit dem die personenbezogenen Daten des Nutzers erhoben und
                verarbeitet werden.<br/>
                Dienst<br/>
                Der durch diese Website angebotene Dienst, wie in den entsprechenden AGBs (falls vorhanden) und
                auf dieser Website/Anwendung beschrieben.<br/>
                Europäische Union (oder EU)
                Sofern nicht anders angegeben, beziehen sich alle Verweise in diesem Dokument auf die Europäische
                Union auf alle derzeitigen Mitgliedstaaten der Europäischen Union und den Europäischen
                Wirtschaftsraum (EWR).<br/>
                Cookie<br/>
                Cookies sind Tracker, die aus einem kleinen, im Browser des Nutzers abgelegten Datensatz bestehen.<br/>
                Tracker<br/>
                Der Begriff Tracker bezeichnet jede Technologie – z. B. Cookies, eindeutige Identifizierungen, Web
                Beacons, eingebettete Skripts, E-Tags oder Fingerprinting – durch die Nutzer nachverfolgt werden
                können, z. B. indem der Zugriff auf oder die Speicherung von Informationen auf dem Nutzergerät
                ermöglicht wird.<br/>
                Rechtlicher Hinweis<br/>
                Diese Datenschutzerklärung wurde auf der Grundlage von Bestimmungen verschiedener
                Gesetzgebungen verfasst, einschließlich Art. 13/14 der Verordnung (EU) 2016/679
                (DatenschutzGrundverordnung).
                Diese Datenschutzerklärung bezieht sich ausschließlich auf diese Website, sofern in diesem
                Dokument nicht anders angegeben
            </p>
        </div>
    )
}