import React, {useEffect, useState} from 'react';
import SystemContext from '../contexts/system-context';


export function SystemProvider(props) {
    const [user, setUser] = useState(localStorage.getItem('authToken'));
    const [loading, setLoading] = useState(false);
    const [config, setConfig] = useState({})
    const [language, setLanguage] = useState('')


    const val = {
        user,
        loading,
        config,
        language,
        setLanguage,
        setConfig,
        setLoading,
        setUser,
    };


    useEffect(() => {
        if(user)
            localStorage.setItem('authToken', user);

    }, [user]);

    return <SystemContext.Provider value={val} {...props} />;
}

export const useSystem = () => {
    const context = React.useContext(SystemContext);

    if (!context) {
        throw new Error('must be used with SystemProvider');
    }

    return {
        ...context,
    };
};

export default {};