import {Button, Modal, Spinner} from "react-bootstrap";
import {useState} from "react";
import './style.scss';
import callAPI from "../../common/api";
import {useTranslation} from "react-i18next";

export default function Unsubscribe({shown, showHide, postSuccess}) {

    const [loading, setLoading] = useState(false);

    const {t} = useTranslation();

    const unsubscribe = () => {
        setLoading(true);
        callAPI({}, 'unsubscribe').then((resp) => {
            if(resp.status === 'ok'){
                postSuccess();
            }
        })
    }

    return (
        <>
            <Modal
                show={shown}
                onHide={() => showHide(false)}
                backdrop="static"
                centered
                keyboard={false}
                closeOnEsc={true}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    {t('confirm_unsubscribe')}
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={loading}  variant="danger" onClick={unsubscribe}>
                        {loading && <Spinner size={'sm'} animation="border" role="status" style={{color:"#FFF"}}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>} {loading? `${t('unsubscribing')}...`: t('unsubscribe')}
                    </Button>
                    <Button disabled={loading} variant="primary" onClick={() => showHide(false)}>{t('keep_subscription')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}