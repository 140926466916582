import {Button, Spinner} from "react-bootstrap";
import {useState} from "react";
import callAPI from "../../common/api";
import {useSystem} from "../../hooks/use-system";

export default function AccessLoader({}) {

    return (
        <div style={{
            backgroundColor: 'blue',
            backgroundImage: 'url(/images/court.png)',
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            backgroundPosition: 'center'
        }}>
            <img src={'/images/logo.jpg'} style={{width: '70%', marginBottom: '2rem', maxWidth: '200px'}}/>
            <Spinner animation="border" role="status" style={{color:"#A1DE29"}}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>

        </div>
    )
}