import {Col, Container, Row, Dropdown, Tab, Table, Tabs} from "react-bootstrap";
import RankingIcon from "../../components/Icons/RankingIcon";

import './style.scss'
import {useEffect, useState} from "react";
import callAPI from "../../common/api";
import {ShimmerTitle} from "react-shimmer-effects";
import ReactCountryFlag from "react-country-flag";
import getCountryISO2 from "country-iso-3-to-2";
import RankingRow from "../../components/RankingRow";
import {useTranslation} from "react-i18next";

export default function Rankings() {

    const {t} = useTranslation();
    const [singles, setSingles] = useState([]);
    const [doubles, setDoubles] = useState([]);

    useEffect(() => {
        if (singles.length === 0)
            callAPI({page_size: 100, page_index: 1, type: 'singles'}, 'getRanking').then((resp) => {
                setSingles(resp.result);
            })
        if (doubles.length === 0)
            callAPI({page_size: 100, page_index: 1, type: 'doubles'}, 'getRanking').then((resp) => {
                setDoubles(resp.result);
            })
    }, []);

    return (
        <Container>
            <Row>
                <div className={'pageHeader'}>
                    <div className={'leftSection'}>
                        <RankingIcon/> <h2>{t('menu.ranking')}</h2>
                    </div>
                    <div className={'rightSection'}>
                        {/*<Dropdown className={'scoresFilter'}>*/}
                        {/*    <Dropdown.Toggle className={'bg-primary text-white'} id="calendarDropDown">*/}
                        {/*        <CalendarIcon/> EVENTS THIS WEEK*/}
                        {/*    </Dropdown.Toggle>*/}

                        {/*    <Dropdown.Menu>*/}
                        {/*        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>*/}
                        {/*        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>*/}
                        {/*        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>*/}
                        {/*    </Dropdown.Menu>*/}
                        {/*</Dropdown>*/}
                    </div>
                </div>
            </Row>
            <Row className={'mt-3 mx-1 rankingPage'}>
                <Tabs
                    defaultActiveKey="Singles"
                    className="rankingPageTabs round"
                    fill
                >
                    <Tab className={'rankingPageTab'} eventKey="Singles" title="Singles">
                        <Table hove className={'m-0'}>
                            <tbody>
                            {singles.length === 0 && <ShimmerTitle className={'mt-5 px-4'} line={10} gap={30} size={'sm'} />}
                            {singles?.map((ranking, i) => {
                                return <RankingRow ranking={ranking} key={`singlesRanking${i}`}/>
                            })}
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab className={'rankingPageTab'} eventKey="Doubles" title="Doubles">
                        <Table hover>
                            <tbody>
                            {doubles.length === 0 && <ShimmerTitle className={'mt-5 px-4'} line={10} gap={30} size={'sm'} />}
                            {doubles?.map((ranking, i) => {
                                return <RankingRow ranking={ranking} key={`doublesRanking${i}`}/>
                            })}
                            </tbody>
                        </Table>
                    </Tab>
                </Tabs>
            </Row>
        </Container>
    )
}