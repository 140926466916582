import {Col, Container, Row} from "react-bootstrap";
import ScoreCard from "../../components/ScoreCard";
import RankingCard from "../../components/RankingCard";
import Head2Head from "../../components/Head2Head";
import {Link} from "react-router-dom";
import LatestNews from "../../components/LatestNews";
import LatestVideos from "../../components/LatestVideos";
import TopPlayers from "../../components/TopPlayers";
import Profile from "../../components/Profile";
import PlaySquareIcon from "../../components/Icons/PlaySquareIcon";
import ScoreIcon from "../../components/Icons/ScoreIcon";
import RankingIcon from "../../components/Icons/RankingIcon";
import UsersIcon from "../../components/Icons/UsersIcon";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import callAPI from "../../common/api";
import {isMobile} from "react-device-detect";


function Home() {

    const {t} = useTranslation();

    const [singles, setSingles] = useState([]);
    const [doubles, setDoubles] = useState([]);

    const [topSinglePlayers, setTopSinglePlayers] = useState([]);
    useEffect(() => {
        if (singles.length === 0)
            callAPI({page_size: 10, page_index: 1, type: 'singles'}, 'getRanking').then((resp) => {
                setSingles(resp.result);
            })
        if (doubles.length === 0)
            callAPI({page_size: 10, page_index: 1, type: 'doubles'}, 'getRanking').then((resp) => {
                setDoubles(resp.result);
            })
    }, []);

    useEffect(() => {
        if(singles.length > 0){
            setTopSinglePlayers( singles.slice(0,isMobile? 3 : 6).map((s) =>  {return s.player}));
        }
    }, [singles]);

    return (
        <Container>
            <Row>
                <Col md={8} className={'text-start equal-height-col'}>
                    <div className={'d-flex flex-row align-items-center justify-content-between'}>
                        <h1 className={'homePageTitle'}><PlaySquareIcon/> {t('titles.latest')}</h1>
                        <Link className={'viewMore'} to={'media'}>{t('viewMore')}</Link>
                    </div>
                    <LatestVideos/>
                </Col>

                <Col md={4} className={'text-start equal-height-col'}>
                    <div className={'d-flex flex-row align-items-center justify-content-between'}>
                        <h1 className={'homePageTitle'}><ScoreIcon/> {t('titles.scores')}</h1>
                        <Link to={'scores'} className={'viewMore'}>{t('seeAll')}</Link>
                    </div>
                    <ScoreCard size={2}/>
                    <div className={'d-flex flex-row align-items-center justify-content-between'}>
                        <h1 className={'homePageTitle'}><RankingIcon/> {t('titles.ranking')}</h1>
                        <Link to={'rankings'} className={'viewMore'}>{t('seeAll')}</Link>
                    </div>
                    <RankingCard singles={singles} doubles={doubles}/>
                </Col>
            </Row>
            <Row className={'mb-5'}>
                <Col md={12} className={'text-start'}>
                    <h1 className={'homePageTitle'}><UsersIcon/> {t('titles.topPlayers')}</h1>
                    <TopPlayers players={topSinglePlayers}/>
                </Col>
                {/*<Col md={4}>*/}
                {/*    <Col md={12} className={'text-start'}>*/}
                {/*        <h1 className={'homePageTitle'}>Head 2 Head</h1>*/}
                {/*        <Head2Head/>*/}
                {/*    </Col>*/}
                {/*</Col>*/}
                {/*<Col md={4}>*/}
                {/*    <Col md={12} className={'text-start'}>*/}
                {/*        <h1 className={'homePageTitle'}>Profile</h1>*/}
                {/*        <Profile/>*/}
                {/*    </Col>*/}
                {/*</Col>*/}
            </Row>
        </Container>
    )
}

export default Home;
