export default function Contact() {

    return (
        <div className={'text-start'}>
            <h2>Adresse und Kontakt</h2>
            <p>
                Cookies Factory s.r.l.<br/>
                Via Cosseria 2<br/>
                00192 Roma, Italy<br/>
                Web: <a href="https://cookies.digital" target="_blank">www.cookies.digital</a>
            </p>
            <h2>Unsere Kundenbetreuung ist für dich erreichbar unter:</h2>
            <p>
                Telefon: 0800 100 336<br/>
                Öffnungszeiten: 8.00-18.00 Uhr<br/>
                E-mail: <a href='mailto:info-at@mobileinfo.biz'>info-at@mobileinfo.biz</a>
                <br/><br/>
                <a href="https://mobileinfo.biz/" target="_blank">https://mobileinfo.biz/</a>
            </p>
        </div>
    )
}