import {Button, Col, Container, Row} from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";

import './style.scss'
import ClockIcon from "../Icons/ClockIcon";
import SlidingPane from "react-sliding-pane";
import {useState} from "react";
import StatsIcon from "../../components/Icons/StatsIcon";
import Head2Head from "../Head2Head";
import getCountryISO2 from "country-iso-3-to-2";
import CheckIcon from "../Icons/CheckIcon";
import moment from "moment";

export default function MatchCard({match}) {

    const [open, setOpen] = useState(false);

    const player_a = match?.team_a.player_a;
    const player_b = match?.team_b.player_a;

    const getFinalName = (p) => {
        let finalName = `${p?.first_name.charAt(0)}. ${p?.last_name}`;
        if (finalName.indexOf('undefined') >= 0){
            finalName = 'TBA';
        }

        return finalName;
    }

    const startDtTime = moment(match?.start_time);

    return (
        <div className="matchCard my-2">
            <Container>
                <Row className={'d-flex align-items-center justify-content-center'}>
                    <Col xs={8} className={'d-flex align-items-center justify-content-start mt-2'}>
                        <p className={'match d-inline'}><b>{match?.round}</b> {match?.court}</p>
                    </Col>
                    <Col xs={4} className={'d-flex align-items-center justify-content-end'}>
                        <p className={'duration'}>
                            <ClockIcon/> &nbsp;
                            {match?.duration}
                            {startDtTime.isAfter() && <p className={'d-inline m-0'}>Starts at {startDtTime.format('HH:mm')}</p>}
                        </p>
                    </Col>
                </Row>
                <Row className={`matchRow mb-1 mx-0 px-2 ${match?.team_a.winner?'winner':''}`}>
                    <Col xs={8}>
                        <Row className={'d-flex align-items-center justify-content-center'}>
                            <Col xs={1}>
                                <ReactCountryFlag countryCode={getCountryISO2(player_a?.nationality_id)}/>
                            </Col>
                            <Col className={'playerName'}>{getFinalName(player_a)}</Col>
                            {match?.team_a.winner && <Col xs={1} className={'text-end'}><CheckIcon /></Col>}
                        </Row>
                    </Col>
                    <Col xs={4} className={'text-end matchScores p-0'}>
                        <Row className={'d-flex align-items-center justify-content-end'}>
                            {match?.team_a?.set_scores?.map((s, i) => {
                                return <Col xs={3} className={'text-center singleScore'}><span>{s.score}{s.score_tie_breaker && <sup>{s.score_tie_breaker}</sup>}</span></Col>
                            })}
                        </Row>
                    </Col>
                </Row>
                <Row className={`matchRow mb-1 mx-0 px-2 ${match?.team_b.winner?'winner':''}`}>
                    <Col xs={8}>
                        <Row className={'d-flex align-items-center justify-content-center'}>
                            <Col xs={1}>
                                <ReactCountryFlag countryCode={getCountryISO2(player_b?.nationality_id)}/>
                            </Col>
                            <Col className={'playerName'}>{getFinalName(player_b)}</Col>
                            {match?.team_b.winner && <Col xs={1} className={'text-end'}><CheckIcon /></Col>}
                        </Row>
                    </Col>
                    <Col xs={4} className={'text-end matchScores p-0'}>
                        <Row className={'d-flex align-items-center justify-content-end'}>
                            {match?.team_b?.set_scores?.map((s, i) => {
                                return <Col xs={3} className={'text-center singleScore'}><span>{s.score}{s.score_tie_breaker && <sup>{s.score_tie_breaker}</sup>}</span></Col>
                            })}
                        </Row>
                    </Col>
                </Row>
                <Row className={'d-flex align-items-center justify-content-center mt-2'}>
                    <Col xs={6} className={'d-flex align-items-center justify-content-start'}>
                        <p className={'match d-inline'}>UMPIRE: <b>{match?.umpire ? (`${match.umpire.first_name} ${match.umpire.last_name}`) : 'TBA'}</b></p>
                    </Col>
                    <Col xs={6} className={'text-end'}>
                        {match?.status_code === 'F' &&
                        <p className={'matchActions'}>
                            <Button onClick={() => setOpen(true)}>H2H</Button>
                            {/*<Button>STATS</Button>*/}
                        </p>
                    }
                    </Col>
                </Row>
            </Container>
            <SlidingPane
                className="matchSlidePanel"
                from={'bottom'}
                isOpen={open}
                onRequestClose={() => {
                    // triggered on "<" on left top click or on outside click
                    setOpen(false)
                }}
            >
                <div className={'pageHeader px-4'}>
                    <div className={'leftSection'}>
                        <StatsIcon/> <h2> HEAD2HEAD</h2>
                    </div>
                    <div className={'rightSection'}>
                        <div onClick={() => setOpen(false)}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.40994 8.00019L15.7099 1.71019C15.8982 1.52188 16.004 1.26649 16.004 1.00019C16.004 0.733884 15.8982 0.478489 15.7099 0.290185C15.5216 0.101882 15.2662 -0.00390625 14.9999 -0.00390625C14.7336 -0.00390625 14.4782 0.101882 14.2899 0.290185L7.99994 6.59019L1.70994 0.290185C1.52164 0.101882 1.26624 -0.00390601 0.999939 -0.00390601C0.733637 -0.00390601 0.478243 0.101882 0.289939 0.290185C0.101635 0.478489 -0.00415253 0.733884 -0.00415254 1.00019C-0.00415254 1.26649 0.101635 1.52188 0.289939 1.71019L6.58994 8.00019L0.289939 14.2902C0.196211 14.3831 0.121816 14.4937 0.0710478 14.6156C0.0202791 14.7375 -0.00585938 14.8682 -0.00585938 15.0002C-0.00585938 15.1322 0.0202791 15.2629 0.0710478 15.3848C0.121816 15.5066 0.196211 15.6172 0.289939 15.7102C0.382902 15.8039 0.493503 15.8783 0.615362 15.9291C0.737221 15.9798 0.867927 16.006 0.999939 16.006C1.13195 16.006 1.26266 15.9798 1.38452 15.9291C1.50638 15.8783 1.61698 15.8039 1.70994 15.7102L7.99994 9.41018L14.2899 15.7102C14.3829 15.8039 14.4935 15.8783 14.6154 15.9291C14.7372 15.9798 14.8679 16.006 14.9999 16.006C15.132 16.006 15.2627 15.9798 15.3845 15.9291C15.5064 15.8783 15.617 15.8039 15.7099 15.7102C15.8037 15.6172 15.8781 15.5066 15.9288 15.3848C15.9796 15.2629 16.0057 15.1322 16.0057 15.0002C16.0057 14.8682 15.9796 14.7375 15.9288 14.6156C15.8781 14.4937 15.8037 14.3831 15.7099 14.2902L9.40994 8.00019Z"
                                    fill="#1160D6"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div>
                    <Head2Head match={match} tmpPlayerA={match?.team_a?.player_a} tmpPlayerB={match?.team_b?.player_a}/>
                </div>

            </SlidingPane>
        </div>
    )
}