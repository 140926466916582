import {Card, Tab, Table, Tabs} from "react-bootstrap";
import './style.scss'
import RankingRow from "../RankingRow";
import TennisBall from "../TenniBall";
import {useTranslation} from "react-i18next";

function RankingCard({singles, doubles}) {

    const {t} = useTranslation();

    return (
        <Card
            text={'white'}
            className="mb-2 rankingCard"
        >
            <Card.Body className={"p-0"}>

                <Tabs
                    defaultActiveKey="Singles"
                    className="rankingTabs"
                    fill
                >
                    <Tab eventKey="Singles" title={t('singles')}>
                        <Table hover className={'m-0'} style={{height: 495}}>
                            <tbody>
                            {singles.length === 0 && <tr><td><TennisBall rotating={true}
                                                                         text={t('messages.loading')}/></td></tr>}
                                {singles?.map((ranking, i) => {
                                return <RankingRow ranking={ranking} key={`singlesRanking${i}`}/>
                            })}
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab eventKey="Doubles" title={t('doubles')}>
                        <Table hover style={{height: 495}}>
                            <tbody>
                            {doubles.length === 0 && <tr><td><TennisBall rotating={true} text={t('messages.loading')}/></td></tr>}
                            {doubles?.map((ranking, i) => {
                                return <RankingRow ranking={ranking} key={`singlesRanking${i}`}/>
                            })}
                            </tbody>
                        </Table>
                    </Tab>
                </Tabs>

            </Card.Body>
        </Card>
    )

}

export default RankingCard;
