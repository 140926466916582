import {Container, Nav, Navbar, NavDropdown, NavItem} from "react-bootstrap";
import Offcanvas from 'react-bootstrap/Offcanvas';

import HomeIcon from "../../components/Icons/HomeIcon";
import ScoreIcon from "../../components/Icons/ScoreIcon";
import RankingIcon from "../Icons/RankingIcon";
import MediaIcon from "../Icons/MediaIcon";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useLocation} from "react-router";
import CalendarIcon from "../Icons/CalendarIcon";
import {useNavigate} from "react-router-dom";
import Unsubscribe from "../Modals/Unsubscribe";
import {useSystem} from "../../hooks/use-system";
import ProfileIcon from "../Icons/ProfileIcon";
import UnsubscribeIcon from "../Icons/UnsubscribeIcon";
import SignoutIcon from "../Icons/SignoutIcon";


function Header({showMenu}) {

    const supportedLanguages = [
        'en', 'de'
    ]

    const system = useSystem();

    const {t, i18n} = useTranslation();

    const [expanded, setExpanded] = useState('expanded');
    const [unsubscribeModal, confirmUnsubscribeModal] = useState(false);

    const {pathname} = useLocation();
    const navigate = useNavigate();

    const params = new URLSearchParams(window.location.search);

    const changeLanguage = (langCode) => {
        i18n.changeLanguage(langCode);
        localStorage.setItem('language', langCode);
        params.set('lang', langCode);
        navigate(`${pathname}?${params.toString()}`);
    }

    const signout = () => {
        localStorage.removeItem('authToken');
        window.location.reload();
    }

    const confirmUnsubscribe = () => {
        confirmUnsubscribeModal(true);
    }

    useEffect(() => {
        let getLang = params.get('lang');
        let savedLang = localStorage.getItem('language');

        if (supportedLanguages.includes(getLang)) {
            savedLang = getLang;
        }

        if (!savedLang) {
            const browserLang = navigator.language.split("-")[0];
            if (supportedLanguages.includes(browserLang))
                savedLang = browserLang;
        }

        if (!supportedLanguages.includes(savedLang))
            savedLang = 'en'

        localStorage.setItem('language', savedLang);
        i18n.changeLanguage(savedLang);

    }, []);


    const profileMenu = (cls) => {
        return <Nav className={cls}>
            <NavDropdown className={'profileDropDown'} align="end" title={<ProfileIcon/>} id={'profile-nav-dropdown'}>
                <NavDropdown.Item
                    onClick={signout}><SignoutIcon/> {t('sign_out')}</NavDropdown.Item>
                <NavDropdown.Item
                    onClick={confirmUnsubscribe}><UnsubscribeIcon/> {t('unsubscribe')}</NavDropdown.Item>
            </NavDropdown>
        </Nav>
    }

    const languageMenu = (cls) => {
        return <Nav className={cls}>
            <NavDropdown className={'languageDropDown'} align="end" title={i18n.language}
                         id="right-nav-dropdown">
                <NavDropdown.Item
                    onClick={() => changeLanguage('en')}>en</NavDropdown.Item>
                <NavDropdown.Item
                    onClick={() => changeLanguage('de')}>de</NavDropdown.Item>
            </NavDropdown>
        </Nav>
    }

    return (
        <Navbar sticky={'top'} expand="lg" className="bg-body-tertiary atpNavBar" data-bs-theme="dark">
            <Container>
                <Navbar.Brand href="/">
                    <img src={'/images/logo.jpg'} alt={'ATP Golden Pass Logo'} width={150}/>
                </Navbar.Brand>

                {languageMenu('ms-auto d-block d-md-none')}
                {profileMenu( 'ms-2 d-block d-md-none')}

                {showMenu &&
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>}
                {showMenu && <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-lg`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                    className={'text-white'}
                    placement="end"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                            <img src={'/images/logo.jpg'} alt={'ATP Golden Pass Logo'} width={150}/>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav expanded={expanded}>
                            <NavItem className={pathname === '/' ? 'active' : ''}>
                                <a href={'/'}><HomeIcon/><span>{t('menu.home')}</span></a>
                            </NavItem>
                            <NavItem className={pathname.startsWith('/scores') ? 'active' : ''}>
                                <a href={'/scores'}><ScoreIcon/><span>{t('menu.score')}</span></a>
                            </NavItem>
                            <NavItem className={pathname.startsWith('/calendar') ? 'active' : ''}>
                                <a href={'/calendar'}><CalendarIcon/><span>{t('menu.calendar')}</span></a>
                            </NavItem>
                            <NavItem className={pathname.startsWith('/rankings') ? 'active' : ''}>
                                <a href={'/rankings'}><RankingIcon/><span>{t('menu.ranking')}</span></a>
                            </NavItem>
                            <NavItem className={pathname.startsWith('/media') ? 'active' : ''}>
                                <a href={'/media'}><MediaIcon/><span>{t('menu.media')}</span></a>
                            </NavItem>
                        </Nav>

                        {languageMenu('ms-auto mt-auto d-none d-md-block')}
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
                }
                {system.user && profileMenu('ms-md-auto d-md-block d-none')}
                <Unsubscribe shown={unsubscribeModal} showHide={confirmUnsubscribeModal} postSuccess={signout}/>
            </Container>
        </Navbar>
    )
}

export default Header;
