import {Card, Tab, Tabs} from "react-bootstrap";
import './style.scss'
import OneEvent from "../OneEvent";
import {useEffect, useState} from "react";
import callAPI from "../../common/api";
import {Link} from "react-router-dom";
import moment from "moment";
import TennisBall from "../TenniBall";
import {useTranslation} from "react-i18next";

function ScoreCard({size, round, dateFilter}) {

    const {t} = useTranslation();

    const [atp, setAtp] = useState(null);
    const [loading, isLoading] = useState({atp: false, ch: false});
    const [challenger, setChallenger] = useState(null);

    const momentDateFilters = dateFilter? moment(dateFilter): null;

    const globalFilters = {
        page_size: size, page_index: 1, order_by: 'play_end_date:desc'
    }

    let dateFilters = {
        play_start_date_since: moment().startOf('month').toISOString(),
        play_start_date_until: moment().endOf('week').toISOString(),
        order_by: 'play_start_date:desc'
    }

    if(momentDateFilters && momentDateFilters.isValid()){
        dateFilters = {
            play_start_date_since: momentDateFilters.toISOString(),
            play_start_date_until: momentDateFilters.add(1, 'month').toISOString(),
            order_by: 'play_start_date:asc'
        }
    }


    const atpFilters = {
        type: '1000|500|250|WC',
    }
    const chFilters = {
        type: 'CH',
        play_start_date_until: dateFilter? moment.utc(dateFilter).toISOString(): moment().toISOString()
    }

    const getTournaments = () => {
        isLoading({atp: true, ch: true});
        callAPI({
            ...globalFilters,
            ...dateFilters,
            ...atpFilters
        }, 'getTournaments').then((resp) => {
            setAtp(resp.result);
            isLoading({atp: false, ch: loading.ch});
        })

        callAPI({
            ...globalFilters,
            ...dateFilters,
            ...chFilters
        }, 'getTournaments').then((resp) => {
            setChallenger(resp.result);
            isLoading({atp: loading.atp, ch: false});
        })
    }

    useEffect(() => {
        getTournaments();
    }, [dateFilter]);


    const cls = 'mb-2 scoreCard' + (round ? ' round': '');

    return (
        <Card
            text={'white'}
            className={cls}
        >
            <Card.Body className={'p-0'}>
                <Tabs
                    defaultActiveKey="Tours"
                    className="scoreTabs"
                    fill
                >
                    <Tab eventKey="Tours" title="ATP Tour">
                        {loading.atp ? <TennisBall rotating={true} text={t('messages.loading')} /> : (
                            atp && atp.length > 0 ? (
                                    atp?.map((e) => {
                                    return <Link className={'eventLink'} to={`/scores/${e.id}`} key={e.id}>
                                    <OneEvent key={e} event={e}/>
                                </Link>})): <TennisBall text={t('messages.check_back_soon')} />
                        )}
                    </Tab>
                    <Tab eventKey="Challenges" title="Challenger">
                        {loading.ch ? <TennisBall rotating={true} text={t('messages.loading')} /> : (
                            challenger && challenger.length > 0 ? (
                                challenger?.map((e) => {
                                    return <Link className={'eventLink'} to={`/scores/${e.id}`} key={e.id}>
                                        <OneEvent key={e} event={e}/>
                                    </Link>})): <TennisBall text={t('messages.check_back_soon')} />
                        )}
                    </Tab>
                </Tabs>
            </Card.Body>
        </Card>
    )

}

export default ScoreCard;
