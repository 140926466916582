import ReactCountryFlag from "react-country-flag";
import getCountryISO2 from "country-iso-3-to-2";
import {useState} from "react";

export default function RankingRow({ranking}) {

    const [opened, slideProfile] = useState(false);

    return (
        <tr>
            <td>{ranking.rank}</td>
            <td><ReactCountryFlag className={'flag'}
                                  countryCode={getCountryISO2(ranking.player.nationality_id)}/>
            </td>
            <td><img src={ranking.player.picture_url} width={35} onClick={() => slideProfile(true)}/></td>
            <td>{ranking.player.first_name} {ranking.player.last_name}</td>
            <td><b>{ranking.points.toLocaleString()}</b></td>
        </tr>
    )
}