export default function Terms() {

    return (
        <div className={'text-start'}>
            <h2>NUTZUNGSBEDINGUNGEN</h2>
            <p>G&Uuml;LTIG AB:&nbsp;Juli 2024</p>
            <div>1. ALLGEMEINES</div>
            <div>a. Diese Nutzungsbedingungen (nachfolgend „NB“) legen die Bedingungen fest unter welcher der Dienst ATP Golden Pass benützt werden kann. ATP Golden Pass ist eine zentrale Plattform für alle ATP-Fans weltweit und bietet ein bereicherndes Nutzererlebnis mit Live-Statistiken, Quizfragen und Umfragen, Echtzeit-Dokumentationen, Turnierinformationen mit Terminen und Spielreihenfolgen, Spielerinformationen und Statistiken mit Fotos sowie Live-Ergebnissen vom Schiedsrichterstuhl. Die vom Diensteanbieter angebotenen Dienste richten sich ausschließlich an natürliche Personen bzw. Verbraucher (i.S.d. § 1 des österreichischen Konsumentenschutzgesetz – KSchG)
            </div>
            <div>b. Die Nutzung des&nbsp;ATP Golden Pass Dienstes unterliegt diesen NBs.</div>
            <div>1. ANGABEN ZUM DIENSTEANBIETER</div>
            <p>Diensteanbieter ist die&nbsp;</p>
            <p>Cookies Factory s.r.l.</p>
            <p>Via Cosseria 2</p>
            <p>00192 Roma, Italy</p>
            <p>Firmenbuchnummer:&nbsp;RM-1374076</p>
            <p>Firmenbuchgericht:&nbsp;Italian Chamber of Commerce</p>
            <p>Umsatzsteuer-Identifikationsnummer:&nbsp;12424661002</p>
            <p>E-Mail:&nbsp;mailto: info@cookies.digital&nbsp;</p>
            <p>Web:&nbsp;www.cookies.digital</p>
            <p>(nachfolgend &bdquo;Diensteanbieter&ldquo;, &bdquo;uns&ldquo;, &bdquo;wir&ldquo; oder&nbsp;Cookies)</p>
            <p>Allgemeine Gesch&auml;ftsbedingungen</p>
            <p>1.1. "Nutzer" oder "Sie" &ndash; jede Person oder juristische Einheit, die auf die Dienste zugreift oder
                diese von jedem Ger&auml;tetyp aus nutzt.</p>
            <p>1.2. Im Falle eines Widerspruchs zwischen den Bestimmungen dieser Bedingungen und den Bestimmungen einer
                anderen Werbung oder Ver&ouml;ffentlichung jeglicher Art wird hiermit klargestellt, dass diese
                Bedingungen und ihre Bestimmungen Vorrang haben.</p>
            <p>Annahme der Bedingungen</p>
            <p>2.1. Durch das Herunterladen, Aktivieren oder Nutzen unserer Dienste erkl&auml;ren Sie sich hiermit
                einverstanden und stimmen diesen Nutzungsbedingungen (die "Bedingungen") zu und sind an sie gebunden.
                WENN SIE DIESEN BEDINGUNGEN NICHT ZUSTIMMEN, BITTE GREIFEN SIE NICHT AUF DIE DIENSTE ZU ODER NUTZEN SIE
                DIESE NICHT. Solange Sie die Nutzung der Dienste nicht einstellen, wird davon ausgegangen, dass Sie
                diese Bedingungen akzeptiert haben.</p>
            <p>2.2. Wir behalten uns das Recht vor, diese Gesch&auml;ftsbedingungen jederzeit und von Zeit zu Zeit
                zu &auml;ndern. Diese Bedingungen, die von Cookies Factory s.r.l. von Zeit zu Zeit ge&auml;ndert werden,
                stellen eine verbindliche und rechtliche Vereinbarung zwischen Ihnen und Cookies Factory s.r.l. dar,
                sobald sie erstmals &uuml;ber einen Link auf der Website ver&ouml;ffentlicht wurden und Sie die Dienste
                erstmals nutzen.</p>
            <p>Dienste und Lizenz</p>
            <p>3.1. Wir behalten uns das Recht vor, die Dienste oder Teile davon sowie den Zugriff des Nutzers darauf
                jederzeit und von Zeit zu Zeit zu &auml;ndern, auszusetzen oder vor&uuml;bergehend oder dauerhaft
                einzustellen und die Anwendungen oder Teile davon zu &auml;ndern, auszusetzen oder zu l&ouml;schen.</p>
            <p>3.2. Die Dienste bieten verschiedene Updates zu Sportereignissen: z.B. Anzeigetafeln, Live-Ergebnisse,
                Wettquoten, Statistiken, Pop-up-Updates und alle anderen Inhalte, die Cookies Factory s.r.l. dem Nutzer
                zur Verf&uuml;gung stellen m&ouml;chte. Dar&uuml;ber hinaus k&ouml;nnen die Dienste Links zu Artikeln im
                Bereich Sport oder anderen Interessengebieten, eine spezielle Suchmaschine sowie Werbe- und
                Marketinginformationen enthalten.</p>
            <p>Nutzungseinschr&auml;nkungen</p>
            <p>4.1. Sofern nicht ausdr&uuml;cklich in dieser Vereinbarung gestattet oder gesetzlich auf nicht
                ausschlie&szlig;barer Basis erforderlich, unterliegt die Nutzung der Dienste den folgenden Verboten:</p>
            <p>4.2. Sie d&uuml;rfen Ihr Recht auf Zugang und Nutzung der Dienste nicht unterlizenzieren;</p>
            <p>4.3. Sie d&uuml;rfen keiner unbefugten Person den Zugriff auf die Dienste oder deren Nutzung
                gestatten;</p>
            <p>4.4. Sie d&uuml;rfen die Dienste nicht nutzen, um Dritten Dienstleistungen anzubieten;</p>
            <p>4.5. Sie d&uuml;rfen den Softwarecode der Plattform nicht kopieren, ver&auml;ndern oder darauf zugreifen
                oder die Integrit&auml;t, Leistung oder Nutzung der Dienste besch&auml;digen, beeintr&auml;chtigen oder
                st&ouml;ren;</p>
            <p>4.6. Sie d&uuml;rfen die Dienste nicht in einer Weise nutzen, die unrechtm&auml;&szlig;ig, illegal,
                betr&uuml;gerisch oder sch&auml;dlich ist, oder in Verbindung mit einem unrechtm&auml;&szlig;igen,
                illegalen, betr&uuml;gerischen oder sch&auml;dlichen Zweck oder einer entsprechenden T&auml;tigkeit;</p>
            <p>4.7. Sie m&uuml;ssen dem Unternehmen so fr&uuml;h wie m&ouml;glich Einzelheiten zu &Auml;nderungen Ihrer
                Richtlinien oder Verfahren mitteilen, die einen Aspekt dieser Vereinbarung oder der Dienste betreffen
                k&ouml;nnten; und</p>
            <p>4.8. Vorbehaltlich Ihrer oder der vorherigen schriftlichen Zustimmung des Unternehmens, je nach
                Anwendbarkeit, d&uuml;rfen sowohl Sie als auch das Unternehmen die allgemeine Gesch&auml;ftsbeziehung
                mit Ihnen oder dem Unternehmen, je nach Anwendbarkeit, f&uuml;r Marketingzwecke referenzieren,
                einschlie&szlig;lich durch Verweise auf ihrer Website. Unbeschadet des Vorstehenden und f&uuml;r den
                Zweck dieses Abschnitts d&uuml;rfen sowohl Sie als auch das Unternehmen den Namen, das Logo und die
                Marken des jeweils anderen w&auml;hrend der Laufzeit verwenden.</p>
            <p>5. Keine kommerzielle Nutzung der Dienste</p>
            <p>Die Dienste sind nicht f&uuml;r Ihre kommerzielle Nutzung oder eine andere nicht pers&ouml;nliche Nutzung
                vorgesehen. Cookies Factory s.r.l., ihre Tochtergesellschaften und Partner sind allein
                berechtigt, &uuml;ber die Dienste Werbung und kommerzielle Informationen zu platzieren.</p>
            <div>6. Registrierung f&uuml;r den Dienst Einige der Dienste k&ouml;nnen eine Registrierung erfordern, in
                welchem Fall Sie m&ouml;glicherweise aufgefordert werden, pers&ouml;nliche Informationen zur
                Registrierung eines Kontos anzugeben. Bitte beachten Sie, dass Sie sich m&ouml;glicherweise nicht
                f&uuml;r die Dienste registrieren k&ouml;nnen, wenn Sie bestimmte f&uuml;r die Registrierung
                erforderliche Angaben nicht machen. Wenn Sie sich entscheiden, sich zu registrieren oder Informationen
                einzureichen, stimmen Sie der Verwendung dieser Daten gem&auml;&szlig; unserer Datenschutzrichtlinie zu,
                und die von Ihnen bereitgestellten Informationen werden in der Datenbank der Cookies Factory s.r.l.
                gespeichert.
            </div>
            <div>7. Eigentum an geistigem Eigentum des Unternehmens</div>
            <p>7.1. Bestimmte Inhalte und Informationen, die auf und durch die Dienste bereitgestellt werden,
                einschlie&szlig;lich, aber nicht beschr&auml;nkt auf das Logo, das Markenzeichen, die Grafiken, das
                Design, die Informationen, den Text, die Bilder, die Daten und andere Materialien, die durch die
                Anwendungen angezeigt, verf&uuml;gbar oder pr&auml;sent sind (der "Inhalt"), sind urheberrechtlich
                gesch&uuml;tzte und/oder markenrechtlich gesch&uuml;tzte Werke der Cookies Factory s.r.l. allein oder
                der Cookies Factory s.r.l.-Partner und d&uuml;rfen nicht ohne ausdr&uuml;ckliche schriftliche
                Genehmigung von uns, au&szlig;er zur Zuschreibung, verwendet werden.</p>
            <p>7.2. Der Ihnen durch die Dienste zur Verf&uuml;gung gestellte Inhalt ist alleiniges Eigentum des
                Unternehmens (oder eines Dritten, falls angegeben) und sofern Sie nicht vorher eine schriftliche
                Genehmigung vom Unternehmen erhalten, au&szlig;er wie hierin anderweitig vorgesehen.</p>
            <p>7.3. Cookies Factory s.r.l. beh&auml;lt alle Rechte, einschlie&szlig;lich aller geistigen
                Eigentumsrechte, an den Inhalten. Der Inhalt oder Teile davon sind alleiniges Eigentum der Cookies
                Factory s.r.l. und durch geltende Gesetze zum Schutz geistigen Eigentums gesch&uuml;tzt. Sie erkennen
                hiermit an, dass Sie keine Rechte, Titel oder Interessen an den Inhalten haben.</p>
            <p>7.4. Sofern in diesen Bedingungen nicht ausdr&uuml;cklich gestattet, haben Sie kein Recht, den Inhalt in
                irgendeiner Weise zu &auml;ndern, zu bearbeiten, zu kopieren, zu reproduzieren, abgeleitete Werke zu
                erstellen, r&uuml;ckzuentwickeln, zu ver&auml;ndern, zu verbessern oder auf irgendeine Weise zu
                nutzen.</p>
            <div>8. Haftungsbeschr&auml;nkung</div>
            <p>8.1. Cookies Factory s.r.l. und ihre Partner oder deren jeweilige Direktoren, leitende Angestellte,
                Mitarbeiter oder Vertreter haften nicht f&uuml;r direkte, indirekte, besondere, zuf&auml;llige,
                Folgesch&auml;den oder exemplarische Sch&auml;den, die sich aus Ihrer Nutzung der Dienste oder aus
                sonstigen Anspr&uuml;chen in irgendeiner Weise im Zusammenhang mit Ihrer Nutzung oder Registrierung bei
                den Diensten ergeben. Diese Ausschl&uuml;sse f&uuml;r direkte, indirekte, besondere, zuf&auml;llige,
                Folgesch&auml;den oder exemplarische Sch&auml;den umfassen unter anderem Sch&auml;den f&uuml;r
                entgangene Gewinne, verlorene Daten, Verlust des Gesch&auml;ftswerts, Arbeitsunterbrechung,
                Computerausfall oder -fehler oder sonstige kommerzielle Sch&auml;den oder Verluste, selbst wenn Cookies
                Factory s.r.l. auf die M&ouml;glichkeit solcher Sch&auml;den hingewiesen wurde und unabh&auml;ngig von
                der rechtlichen oder billigen Theorie, auf der der Anspruch basiert. Da einige Rechtsordnungen den
                Ausschluss oder die Beschr&auml;nkung der Haftung f&uuml;r Folgesch&auml;den oder zuf&auml;llige
                Sch&auml;den nicht zulassen, ist in solchen Rechtsordnungen die Haftung von Cookies Factory s.r.l. auf
                den gesetzlich zul&auml;ssigen Umfang beschr&auml;nkt.</p>
            <p>8.2. Cookies Factory s.r.l. unterst&uuml;tzt, garantiert oder gew&auml;hrleistet keine Produkte oder
                Dienstleistungen, die &uuml;ber diese Website angeboten werden, und ist nicht Partei oder in irgendeiner
                Weise verantwortlich f&uuml;r die &Uuml;berwachung von Transaktionen zwischen Ihnen und Drittanbietern
                von Produkten oder Dienstleistungen. Der Benutzer erkennt an und stimmt zu, dass Cookies Factory s.r.l.
                ohne die vorstehenden Ausschl&uuml;sse und Haftungsbeschr&auml;nkungen die Dienste nicht anbieten
                k&ouml;nnte.</p>
            <div>9. Haftungsausschl&uuml;sse 9.1. Der Benutzer erkennt an und stimmt zu, dass die Dienste und deren
                Inhalte "wie besehen", "wie verf&uuml;gbar" und "mit allen Fehlern" bereitgestellt werden und nur auf
                eigenes Risiko in vollem gesetzlich zul&auml;ssigem Umfang genutzt werden. Cookies Factory s.r.l. lehnt
                alle ausdr&uuml;cklichen oder stillschweigenden Gew&auml;hrleistungen jeglicher Art in Bezug auf die
                Dienste ab (einschlie&szlig;lich deren Inhalte, Produkte, Informationen, Software und Links) und
                einschlie&szlig;lich aller stillschweigenden Gew&auml;hrleistungen bez&uuml;glich Eignung f&uuml;r einen
                bestimmten Zweck, Marktg&auml;ngigkeit, Titel, Nichtverletzung, Ergebnisse, Genauigkeit,
                Vollst&auml;ndigkeit, Zug&auml;nglichkeit, Kompatibilit&auml;t, Eignung, Zuverl&auml;ssigkeit,
                Verf&uuml;gbarkeit, Aktualit&auml;t, Qualit&auml;t oder Virenfreiheit. Wenn geltendes Recht den
                Ausschluss einiger oder aller der oben genannten stillschweigenden Gew&auml;hrleistungen f&uuml;r Sie
                nicht zul&auml;sst, gelten die oben genannten Ausschl&uuml;sse f&uuml;r Sie im vollen gesetzlich
                zul&auml;ssigen Umfang.
            </div>
            <p>9.2. Jegliche Informationen zu Sportergebnissen, Live-Scores, Wettquoten, Sportupdates, Statistiken oder
                andere Informationen, die &uuml;ber die Dienste ver&ouml;ffentlicht werden, werden von verschiedenen
                Drittanbietern bereitgestellt und ver&ouml;ffentlicht und nicht im Namen von Cookies Factory s.r.l. oder
                deren Partnern oder Vertretern. Bitte beachten Sie, dass solche Informationen keine Beratung, Empfehlung
                oder autorisierte Meinung darstellen und nicht die Notwendigkeit einer Konsultation mit einem Fachmann
                ersetzen, soweit dies erforderlich ist. Zur Vermeidung von Missverst&auml;ndnissen haftet Cookies
                Factory s.r.l. nicht f&uuml;r Fehler, Irrt&uuml;mer oder Ungenauigkeiten in den &uuml;ber die Dienste
                ver&ouml;ffentlichten Informationen. Sie entbinden hiermit Cookies Factory s.r.l. von jeglicher Haftung
                f&uuml;r solche Fehler, Irrt&uuml;mer oder Ungenauigkeiten und/oder f&uuml;r jegliche Sch&auml;den, die
                Ihnen durch Ihr Vertrauen auf &uuml;ber die Dienste angezeigte Informationen entstehen.</p>
            <p>9.4. Bitte beachten Sie, dass die Dienste Links zu anderen Websites, Anwendungen oder Add-ons enthalten
                k&ouml;nnen, die von Dritten betrieben werden und m&ouml;glicherweise nicht durch diese Bedingungen
                geregelt sind. Cookies Factory s.r.l. ist nicht verantwortlich f&uuml;r die Kompatibilit&auml;t oder
                Natur solcher Links oder deren Inhalte.</p>
            <div>10. &Auml;nderungen und Aufbewahrung</div>
            <p>10.1. Cookies Factory s.r.l. beh&auml;lt sich das Recht vor, Inhalte des Benutzers jederzeit und von Zeit
                zu Zeit ohne Ank&uuml;ndigung aus ihren Datenbanken zu l&ouml;schen, und es liegt in der Verantwortung
                des Benutzers, solche Inhalte oder Daten zu sichern. Wir k&ouml;nnen auch Ihr Konto (falls zutreffend)
                und den Zugang zur Nutzung der Dienste deaktivieren, und Cookies Factory s.r.l. kann von Ihnen Verluste,
                Sch&auml;den, Kosten oder Ausgaben zur&uuml;ckfordern, die Cookies Factory s.r.l. durch Ihre
                Nichteinhaltung einer Bestimmung dieser Bedingungen entstehen.</p>
            <p>10.2. Cookies Factory s.r.l. kann jederzeit die Dienste oder Teile davon vor&uuml;bergehend oder
                dauerhaft &auml;ndern, aussetzen oder einstellen, Beschr&auml;nkungen auferlegen, nach eigenem Ermessen
                f&uuml;r einen bestimmten Benutzer oder alle Benutzer, ohne Ank&uuml;ndigung, einschlie&szlig;lich, aber
                nicht beschr&auml;nkt auf Sperrung, Abschaltung, Einschr&auml;nkung und Einstellung von Diensten aus
                jeglichem Grund.</p>
            <p>10.3. Wenn Sie die Dienste nutzen, erkennen Sie hiermit an und stimmen zu, dass Cookies Factory s.r.l.
                Ihnen oder Dritten gegen&uuml;ber nicht haftbar ist f&uuml;r jegliche &Auml;nderung, Aussetzung oder
                Einstellung der Dienste oder jegliche &Auml;nderung, Aussetzung oder L&ouml;schung der Anwendungen, und
                Sie verzichten auf jeglichen Anspruch bez&uuml;glich des Vertrauens auf die Dienste.</p>
            <p>10.4. Diese Nutzungsbedingungen k&ouml;nnen von uns jederzeit und von Zeit zu Zeit ge&auml;ndert werden.
                Es ist unsere Richtlinie, &Auml;nderungen dieser Nutzungsbedingungen auf unserer Website zu
                ver&ouml;ffentlichen. &Auml;nderungen dieser Nutzungsbedingungen werden verbindlich und wirksam, wenn
                sie auf unserer Website ver&ouml;ffentlicht werden. Ihre fortgesetzte Nutzung der Website oder ihrer
                Dienste nach Inkrafttreten der ge&auml;nderten Nutzungsbedingungen stellt Ihre Zustimmung zu diesen
                dar.</p>
            <div>11. R&Uuml;CKTRITTSRECHT</div>
            <p>11.1 Gem&auml;&szlig; &sect; 11 Fern- und Ausw&auml;rtsgesch&auml;ftegesetzes (FAGG) haben Sie
                grunds&auml;tzlich das Recht, von einem mit uns f&uuml;r den Play&amp;Play Dienst abgeschlossen Vertrag
                innerhalb von 14 (vierzehn) Tagen ohne Angabe von Gr&uuml;nden zur&uuml;cktreten, dies ab dem Tag des
                Vertragsabschlusses. Die Aus&uuml;bung des R&uuml;cktrittsrechts f&uuml;hrt dazu, dass Sie nichts zu
                bezahlen haben bzw. eine erfolgte Zahlung an Ihnen zur&uuml;ckzuerstatten ist. Das
                Muster-Widerrufsformular k&ouml;nnen Sie hier finden: <a href={'https://atp-fe-dev.cookies.digital/docs/Widerrufsformular.pdf'} target={'_blank'}>Widerrufsformular</a>.</p>
            <p>11.2Gem&auml;&szlig; &sect; 18 Abs 1 Z 11 FAGG Sie verlieren jedoch Ihr R&uuml;cktrittsrecht wenn</p>
            <p>a) der mit uns abgeschlossene Vertrag die Lieferung von nicht auf einem k&ouml;rperlichen
                Datentr&auml;ger gespeicherten digitalen Inhalten betrifft (was im Hinblick auf den Play&amp;Play Dienst
                der Fall ist),</p>
            <p>b) wir Ihnen eine Best&auml;tigung des Vertrages &uuml;bermitteln (dies ist in der Regel eine SMS),
                und</p>
            <p>c) Sie ausdr&uuml;cklich zugestimmt haben, dass wir mit der Ausf&uuml;hrung des Vertrags noch vor Ablauf
                der sonst bestehenden 14-t&auml;gigen R&uuml;cktrittsfrist beginnen, und zur Kenntnis nehmen, dass Sie
                mit dieser Zustimmung Ihr R&uuml;cktrittsrecht verlieren.</p>
            <div>12. DATENSCHUTZ</div>
            <p>a. MIT DER NUTZUNG UNSERES DIENSTES GEBEN SIE IHRE AUSDR&Uuml;CKLICH ZUSTIMMUNG, DASS IHRE
                PERS&Ouml;NLICHEN DATEN, N&Auml;MLICH</p>
            <p>&bull; IHRE RUFNUMMER</p>
            <p>ZUM ZWECK DER ERBRINGUNG DES DIENSTES VERARBEITET WERDEN UND AN IHREN MOBILEFUNKANBIETER (ENTWEDER DIREKT
                ODER &Uuml;BER UNSEREN TECHNISCHEN ZAHLUNGSDIENSTLEISTER) ZUM ZWECK DER ABRECHNUNG &Uuml;BER IHRE
                MOBILFUNKRECHNUNG &Uuml;BERMITTELT WERDEN.</p>
            <p>b. Sie k&ouml;nnen die unter Punkt 12.a dieser NBs abgegebene Zustimmung jederzeit schriftlich widerrufen
                (E-Mail an finance@cookies.digital gen&uuml;gt)</p>
            <div>13. Abtretung</div>
            <p>Cookies Factory s.r.l. kann die Bedingungen jederzeit und/oder jegliche ihrer Rechte und/oder Pflichten
                aus diesen Bedingungen ganz oder teilweise, einschlie&szlig;lich, aber nicht beschr&auml;nkt auf die
                Vergabe von Lizenzen oder Unterlizenzen, an Dritte abtreten und/oder &uuml;bertragen.</p>
            <div>14. Kontaktinformationen</div>
            <p>Wenn Sie Fragen oder Bedenken bez&uuml;glich dieser Nutzungsbedingungen haben, kontaktieren Sie uns bitte
                unter: info@cookies.digital.</p>
        </div>
    )
}