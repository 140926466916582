import {BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import './App.scss';
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import StaticPage from "./pages/StaticPage";
import ArticleDetails from "./pages/ArticleDetails";
import VideoDetails from "./pages/VideoDetails";
import Scores from "./pages/Scores";
import OneScore from "./pages/OneScore";
import {useSystem} from "./hooks/use-system";
import {useEffect, useState} from "react";
import callAPI from "./common/api";
import AccessLoader from "./components/AccessLoader";
import Rankings from "./pages/Rankings";
import Media from "./pages/Media";
import Authenticate from "./pages/Authenticate";
import publicRoutes from "./config/publicRoutes";
import Calendar from "./pages/Calendar";

function App() {

    const system = useSystem();
    const pathname = window.location.pathname;

    //const aId = '';//'320ffa40-7c1c-11ef-99ab-06e87fb18c1e';

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const aId = params.get('a');

    const [reqCalled, reqCall] = useState(false);
    const [canAccess, setAccess] = useState(false);
    const [msisdnIsVisible, showMsisdBox] = useState(false);

    const title = 'ATP Golden Pass';

    useEffect(() => {
        if (!canAccess && !reqCalled) {
            reqCall(true);
            fetchSettings();

            if(aId){
                validateAuth(aId);
            }else if(system.user){
                setAccess(true);
            } else if(!publicRoutes.includes(pathname)){
                redirectToAuthenticate()
            }
        }
    }, []);

    const redirectToAuthenticate = () => {
        window.location = '/authenticate';
    }

    const fetchSettings = () => {
        callAPI({}, 'loadSettings').then((resp) => {
            if(resp.status === 'ok') {
                //Save config
            }
        });
    }

    const validateAuth = (accessCode) => {
        showMsisdBox(false);
        callAPI({access_code: accessCode}, 'validateAuth').then((resp) => {
            if(resp.status === 'ok') {
                system.setUser(resp.result.auth_token);
                setAccess(true);
                window.location = window.location.pathname
            }else{
                redirectToAuthenticate();
            }
        })
    }

    return (
        <div className={'App'}>
            <BrowserRouter>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{title}</title>
                    <meta property="og:title" content={title}/>
                    <meta property="og:description" content=""/>
                    <meta property="og:image:secure_url" content=""/>
                    <meta property="og:url" content={window.location.href}/>
                    <meta name="description" content=""/>
                    <meta property="og:type" content="website"/>
                    <meta name="author" content="Cookies"/>
                </Helmet>
                <Header showMenu={canAccess}/>
                <Routes>
                    <Route path={'withdrawal'} element={<StaticPage/>} key={'Withdrawal'}/>
                    <Route path={'contact'} element={<StaticPage/>} key={'Contact'}/>
                    <Route path={'privacy'} element={<StaticPage/>} key={'Privacy'}/>
                    <Route path={'impressum'} element={<StaticPage/>} key={'Impressum'}/>
                    <Route path={'terms'} element={<StaticPage/>} key={'Terms'}/>
                    <Route path={'authenticate'} element={<Authenticate/>} key={'Authenticate'}/>
                    {
                        canAccess ? (
                            <>
                                {/*<Route path={'article/:slug'} element={<ArticleDetails/>} key={'Article'}/>*/}
                                <Route path={'media/video/:slug'} element={<Media/>} key={'Media'}/>
                                <Route path={'media'} element={<Media/>} key={'Media'}/>
                                <Route path={'rankings'} element={<Rankings/>} key={'Ranking'}/>
                                <Route path={'calendar'} element={<Calendar/>} key={'Calendar'}/>
                                <Route path={'scores'} element={<Scores/>} key={'Scores'}/>
                                <Route path={'scores/:tournamentId'} element={<OneScore/>} key={'One Score'}/>
                                <Route path={'/'} element={<Home/>} key={'Home'}/>
                            </>
                        ) : (
                            <Route path={'*'} element={<AccessLoader />} key={'Home'}/>
                        )
                    }

                </Routes>
                <Footer/>
            </BrowserRouter>
        </div>
    );
}

export default App;
