import {Card} from "react-bootstrap";
import './style.scss'
import PlayRoundIcon from "../Icons/PlayRoundIcon";
import ArticleTag from "../ArticleTag";
import React from "react";
import ReactPlayer from "react-player";
import {useTranslation} from "react-i18next";


function ArticleCard({article, view = 'grid', tags = [], small = false, player = false}) {

    const {t, i18n} = useTranslation()

    const finalCoverImgUrl = article?.metas?.cover.replace('${width}', small? '250':'1200');

    const getLocalizedTitle = () => {
        let tmpTitle = article?.title;
        try{
            const localeTitle = article?.metas[`title.${i18n.language}`];
            if(localeTitle){
                tmpTitle = localeTitle;
            }
        }catch(e){}
        return tmpTitle
    }

    const getLocalizedDesc = () => {
        let tmpDescription;
        try{
            const localeDesc = article?.metas[`description.${i18n.language}`];
            if(localeDesc){
                tmpDescription = localeDesc;
            }
        }catch(e){}
        return tmpDescription
    }

    return (
        <a href={!player ? `/media/video/${article.id}` : 'javascript:void(0)'}>
            <Card text={'white'} className={`text-start articleCard ${view} ${small ? 'small' : null}`}>

                <div className={'mediaWrapper'}>
                    {article?.hls_url && player ? (
                            <ReactPlayer width={'100%'} height={'auto'} playing={true} url={article.hls_url}
                                         controls={true} light={<img
                                src={finalCoverImgUrl} alt='Thumbnail' className={'img-fluid'}/>}/>
                        ) :
                        <>
                            <Card.Img variant="top" src={finalCoverImgUrl} fall={'true'} />
                            {article?.hls_url &&
                                <div className={'video-cta'}>
                                    <PlayRoundIcon/>
                                </div>
                            }
                        </>
                    }
                </div>
                <Card.Body>
                    <div>
                        {article?.category &&
                            <ArticleTag label={article.category} />
                        }
                    </div>
                    <Card.Title>{getLocalizedTitle()}</Card.Title>
                    {getLocalizedDesc() && <Card.Text>
                        {getLocalizedDesc()}
                    </Card.Text>}
                </Card.Body>
            </Card>
        </a>
    )
}

export default ArticleCard;
