import {Badge} from "react-bootstrap";
import {useTranslation} from "react-i18next";

export default function ArticleTag({label}) {

    const {t} = useTranslation();
    const colorDict = {
        'highlights': {bg: '#A1DE29', fg: '#000'},
        'hot_shots': {bg: '#309CFF', fg: '#FFF'},
        'interviews': {bg: '#F29057', fg: '#FFF'},
        'uncovered': {bg: '#FFBD97', fg: '#000'},
        'live': {bg: 'red', fg: '#FFF'}
    }

    const sanitizeLabel = () => {
        return t(`tags.${label}`).replace("_"," ").toUpperCase();
    }

    return Object.keys(colorDict).includes(label) && <Badge className={'m-1'} bg={''} style={{
        backgroundColor: colorDict[label].bg, color: colorDict[label].fg
    }}>{sanitizeLabel()}</Badge>
}