export default function MediaIcon(props) {
    return <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0.929688 3.43359C0.929688 1.77674 2.27283 0.433594 3.92969 0.433594H17.9297C19.5865 0.433594 20.9297 1.77674 20.9297 3.43359V12.9336C20.9297 14.5904 19.5865 15.9336 17.9297 15.9336H3.92969C2.27283 15.9336 0.929688 14.5904 0.929688 12.9336V3.43359ZM3.92969 2.43359C3.3774 2.43359 2.92969 2.88131 2.92969 3.43359V12.9336C2.92969 13.4859 3.3774 13.9336 3.92969 13.9336H17.9297C18.482 13.9336 18.9297 13.4859 18.9297 12.9336V3.43359C18.9297 2.88131 18.482 2.43359 17.9297 2.43359H3.92969Z"
              fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M15.5068 8.18349C15.5068 8.55778 15.2978 8.90073 14.9652 9.07228L9.59978 11.839C9.28978 11.9989 8.91888 11.9857 8.62104 11.8041C8.3232 11.6226 8.14147 11.299 8.14147 10.9502L8.14147 5.41675C8.14147 5.06795 8.3232 4.74436 8.62104 4.56284C8.91888 4.38132 9.28978 4.3681 9.59978 4.52796L14.9652 7.2947C15.2978 7.46624 15.5068 7.8092 15.5068 8.18349ZM10.1415 7.05754L10.1415 9.30943L12.3249 8.18349L10.1415 7.05754Z"
              fill="white"/>
    </svg>


}