import {Col, Row} from "react-bootstrap";
import PlayerCard from "../PlayerCard";

import {ShimmerButton, ShimmerTitle} from "react-shimmer-effects";

import './style.scss'

export default function TopPlayers({players}) {

    return (
        <Row className={'mb-3 m-0 p-0'}>
            {players.length === 0 && (
                [1, 2, 3].map((i) => {
                    return <Col key={`playerShimmer${i}`} md={4} xs={12} className={'m-0 my-1 p-0 p-md-1 playerCardShimmer'}>
                        <ShimmerButton size={'lg'}/>
                    </Col>
                })
            )}
            {players.map((player, i) => {
                return <Col key={`playerCol${i}`} md={4} xs={12} className={'m-0 my-1 p-0 p-md-1'}>
                    <PlayerCard key={`playerCard${i}`}  tmpPlayer={player} rank={i + 1}/>
                </Col>
            })}
        </Row>
    )
}