
import './style.scss'
export default function TournamentType({t, size='normal'}) {

    const d = {
        '1000': '/images/tournaments/atp_1000.png',
        '500': '/images/tournaments/atp_500.png',
        '250': '/images/tournaments/atp_250.png',
        'CH': '/images/tournaments/atp_ch.png',
        'WC': '/images/tournaments/nitto.png',
    }

    const v = d[t];

    return v ? <img src={v}  alt={t} className={`tournament ${size}`}/> : null;
}