import {Col, Container, Row} from "react-bootstrap";
import "./style.scss";

function Footer() {
    return (
        <Container fluid className={'footer mt-5'}>
            <Container>
                <Row className="row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 border-top text-start">

                    <Col xs={12} className={'mb-3'}>
                        <img src={'/images/logo.png'} alt="logo" width={150}/>
                    </Col>

                    <Col xs={6} className="mb-3">
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/withdrawal"
                                                             className="nav-link p-0 text-body-secondary">Rücktrittsrecht</a>
                            </li>
                            <li className="nav-item mb-2"><a href="/contact"
                                                             className="nav-link p-0 text-body-secondary">Kontaktiere
                                uns</a>
                            </li>
                            <li className="nav-item mb-2"><a target={'blank'} href="https://de.mobileinfo.biz/login.html"
                                                             className="nav-link p-0 text-body-secondary">Abo Verwaltung</a>
                            </li>
                        </ul>
                    </Col>

                    <Col xs={6} className="mb-3">
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/privacy"
                                                             className="nav-link p-0 text-body-secondary">Datenschutz</a>
                            </li>
                            <li className="nav-item mb-2"><a href="/impressum"
                                                             className="nav-link p-0 text-body-secondary">Impressum</a>
                            </li>
                            <li className="nav-item mb-2"><a href="/terms"
                                                             className="nav-link p-0 text-body-secondary">Nutzungsbedingungen</a>
                            </li>
                        </ul>
                    </Col>

                    <Col xs={12} className="mb-3">
                        <p className="text-white">&copy; 2024</p>
                    </Col>

                    {/*<Col className="mb-3">*/}
                    {/*    <h5>Section</h5>*/}
                    {/*    <ul className="nav flex-column">*/}
                    {/*        <li className="nav-item mb-2"><a href="#home"*/}
                    {/*                                         className="nav-link p-0 text-body-secondary">Home</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="nav-item mb-2"><a href="#home"*/}
                    {/*                                         className="nav-link p-0 text-body-secondary">Features</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="nav-item mb-2"><a href="#home"*/}
                    {/*                                         className="nav-link p-0 text-body-secondary">Pricing</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="nav-item mb-2"><a href="#home"*/}
                    {/*                                         className="nav-link p-0 text-body-secondary">FAQs</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="nav-item mb-2"><a href="#home"*/}
                    {/*                                         className="nav-link p-0 text-body-secondary">About</a>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</Col>*/}
                </Row>
            </Container>
        </Container>
    )
}

export default Footer;
