export default function Withdrawal() {

    return (
        <div className={'text-start'}>
            <h2>HINWEIS AUF RÜCKTRITTSRECHT</h2>
            <p>
                1. Gemäß § 11 Fern- und Auswärtsgeschäftegesetzes (FAGG) haben Sie grundsätzlich das Recht,
                von einem
                mit
                uns für den ATP Golden Pass. Dienst abgeschlossenen Vertrag innerhalb von 14 (vierzehn)
                Tagen
                ohne Angabe
                von
                Gründen zurückzutreten, dies ab dem Tag des Vertragsabschlusses. Die Ausübung des
                Rücktrittsrechts führt
                dazu, dass Sie nichts zu bezahlen haben bzw. eine erfolgte Zahlung an Sie zurückerstattet
                wird. Das
                Muster-Widerrufsformular können Sie hier finden:
                <br/>
                <a href="/docs/Widerrufsformular.pdf"
                   className="hover:text-blue-800" target="_blank">Widerrufsformular</a>
            </p>

            <p>

                2. Gemäß § 18 Abs 1 Z 11 FAGG verlieren Sie jedoch Ihr Rücktrittsrecht wenn

                <ul>
                    <li>
                        I. der mit uns abgeschlossene Vertrag die Lieferung von nicht auf einem körperlichen
                        Datenträger
                        gespeicherten
                        digitalen Inhalten betrifft (was im Hinblick auf den ATP Golden Pass. Dienst der Fall
                        ist),
                    </li>

                    <li>
                        II. wir Ihnen eine Bestätigung des Vertrages übermitteln (dies ist in der Regel eine
                        SMS), und
                    </li>

                    <li>
                        III. Sie ausdrücklich zugestimmt haben, dass wir mit der Ausführung des Vertrages noch
                        vor Ablauf
                        der
                        sonst
                        bestehenden 14-tägigen Rücktrittsfrist beginnen, und zur Kenntnis nehmen, dass Sie mit
                        dieser
                        Zustimmung Ihr
                        Rücktrittsrecht verlieren.
                    </li>
                </ul>
            </p>
        </div>
    )
}