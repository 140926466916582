import {Button, Col, Container, Row, Spinner} from "react-bootstrap";

import './style.scss'
import {useSystem} from "../../hooks/use-system";
import {useEffect, useRef, useState} from "react";

import callAPI from "../../common/api";
import {useTranslation} from "react-i18next";
import IMask from 'imask';


export default function Authenticate() {

    const {t} = useTranslation();

    const system = useSystem();
    const allowedCountries = ['AUSTRIA'];
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [loading, isLoading] = useState(false);


    const validateMsisdn = () => {
        isLoading(true);
        callAPI({msisdn: phoneNumber}, 'validateMsisdn').then((resp) => {
            if(resp.status === 'ok') {
                system.setUser(resp.result.auth_token);
                window.location = '/'
            }else{
                isLoading(false);
                setPhoneNumber(null)
            }
        })
    }

    const spinner = () => {
        return <Spinner size="sm" animation="border" role="status" style={{color:"#000"}}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    }

    useEffect(() => {
        const element = document.getElementById('phoneNumber');
        const maskOptions = {
            mask: '+43 000 000 0000',
            lazy: false,
            placeholder: "-"
        };
        const mask = IMask(element, maskOptions);
        mask.on('complete', () => {
            setPhoneNumber(`43${mask.typedValue}`) ;
        });
    }, []);


    return (
        <Container className={'px-0'} id={'loginContainer'}>
            <Row>
                <Col>
                    <img src={'/images/login.jpg'} width={'100%'} />
                </Col>
            </Row>
            <Row className={'px-4 my-2 align-items-center justify-content-center'}>
                <Col md={6}>
                    <h2 dangerouslySetInnerHTML={{__html: t('authenticate.title')}}></h2>
                    <h4 className={'mt-5'} dangerouslySetInnerHTML={{__html: t('authenticate.subtitle')}}></h4>
                    <>
                        <input id={'phoneNumber'} inputMode="numeric" type={'text'} className={'phoneNumber form-control'} name={'phoneNumber'} />
                        <br/>
                        <Button className={'brandButton my-4'} onClick={validateMsisdn} disabled={loading || phoneNumber === ''}>
                            {loading && spinner()}
                            {!loading ? t('authenticate.cta'): t('authenticate.cta_loading')}
                        </Button>
                    </>
                </Col>
            </Row>
        </Container>
    )
}