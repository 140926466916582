import {Col, Container, Row, Dropdown} from "react-bootstrap";
import ScoreIcon from "../../components/Icons/ScoreIcon";
import CalendarIcon from "../../components/Icons/CalendarIcon";
import ScoreCard from "../../components/ScoreCard";

import './style.scss'
import {useTranslation} from "react-i18next";

export default function Scores({data}) {

    const {t} = useTranslation();

    return (
        <Container>
            <Row>
                <div className={'pageHeader'}>
                    <div className={'leftSection'}>
                        <ScoreIcon/> <h2>{t('titles.scores')}</h2>
                    </div>
                    <div className={'rightSection'}>
                        <Dropdown className={'scoresFilter'}>
                            <Dropdown.Toggle className={'bg-primary text-white'} id="calendarDropDown">
                                <CalendarIcon/> {t('events_this_week')}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item active >{t('events_this_week')}</Dropdown.Item>
                                <Dropdown.Item onClick={() => window.location = '/calendar'}>{t('archive')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </Row>
            <Row className={'mt-3'}>
                <ScoreCard size={10} round={true}/>
            </Row>
        </Container>
    )
}