const baseURL = process.env.REACT_APP_API_BASE_URL
const testBaseURL = process.env.REACT_APP_API_TEST_BASE_URL
const clientID = process.env.REACT_APP_CLIENT_ID

const actions = {
    loadSettings: data => ({
        method: 'get',
        url: '/v1/client-settings',
    }),
    validateAuth: data => ({
        method: 'post',
        url: '/v1/auth/access-code',
    }),
    unsubscribe: data => ({
        method: 'put',
        url: '/v1/unsub',
    }),
    validateMsisdn: data => ({
        method: 'post',
        url: '/v1/auth/msisdn',
    }),
    getTournaments: data => ({
        method: 'get',
        url: '/v1/atp/tournaments'
    }),
    getMatches: data => ({
        method: 'get',
        url: '/v1/atp/matches'
    }),
    getRanking: data => ({
        method: 'get',
        url: '/v1/atp/rankings'
    }),
    getPlayers: data => ({
        method: 'get',
        url: '/v1/atp/players'
    }),
    getNews: data => ({
        method: 'get',
        url: testBaseURL + '/data/news.json'
    }),
    getVideos: data => ({
        method: 'get',
        url: '/v1/atp/videos'
    }),
    getArticle: data => ({
        method: 'get',
        url: testBaseURL + '/data/articles/'
    }),
    getProfile: data => ({
        method: 'get',
        url: testBaseURL + '/data/profile.json'
    })

}

const getAuthToken = () => {
    return localStorage.getItem('authToken')
}

const callAPI =
    (data, type, linkSuffix='') => {

        try {
            let dt = actions[type](data);

            let requestOptions = {
                method: dt.method,
                headers: {
                    'X-CLIENT-ID': clientID,
                    'X-AUTH-TOKEN': getAuthToken(),
                },
            };
            if(dt.method === 'post') {
                requestOptions.body = JSON.stringify(data);
                requestOptions.redirect = 'follow';
                requestOptions.headers['Content-Type'] = "application/json";
            }

            if (type === 'getArticle')
                dt.url += data.slug + '.json';

            let finalAPIURL = dt.url.indexOf('http') >= 0 ? dt.url+linkSuffix : (baseURL + dt.url+linkSuffix);

            if(dt.method === 'get' && data){
                finalAPIURL +=  '?' + Object.entries(data).map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`).join('&');
            }

            return fetch(finalAPIURL, requestOptions)
                .then(resp => {
                    if(!resp.ok)
                    {
                        localStorage.removeItem('authToken');
                        window.location ='/';
                    }else{
                        return resp.json()
                    }
                })
                .then(function (resp) {
                    return resp;
                })
                .catch(function (error) {
                    throw new Error(error);
                });
        } catch (err) {
            console.error(err);
        }
    };

export default callAPI;