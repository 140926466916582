import {Card, Col, Container, Row, Table} from "react-bootstrap";
import "./style.scss"
import {useEffect, useState} from "react";
import callAPI from "../../common/api";
import ReactCountryFlag from "react-country-flag";
import TournamentType from "../TournamentType";

function Head2Head({tmpPlayerA, tmpPlayerB, match}) {

    const [playerA, setPlayerA] = useState(tmpPlayerA);
    const [playerB, setPlayerB] = useState(tmpPlayerB);
    useEffect(() => {
        getPlayer(playerA.id, (tmpPlayer) => {
            setPlayerA(tmpPlayer);
        })
        getPlayer(playerB.id, (tmpPlayer) => {
            setPlayerB(tmpPlayer);
        })
    }, []);


    const getPlayer = (id, successFn) => {

        callAPI({}, 'getPlayers', `/${id}`).then((resp) => {
            let tmpPlayer = resp.result;
            if(tmpPlayer) {
                if(tmpPlayer.picture_url && tmpPlayer.picture_url.indexOf('/player-headshot/') > 0){
                    tmpPlayer.standing_picture_url = tmpPlayer.picture_url.replace('/player-headshot/','/player-gladiator-headshot/');
                }
                successFn(tmpPlayer)
            }
        })
    }

    const stats = [
        {'key': 'Age', 'value': 'age'},
        {'key': 'Height(cm)', 'value': 'height_cm'},
        {'key': 'Weight(kg)', 'value': 'weight_kg'},
        {'key': 'Birthplace', 'value': 'birth_city'},
        {'key': 'Plays', 'value': 'backhand_style'},
        {'key': 'Backhand', 'value': 'handedness'},
    ]

    return (
        <Card
            text={'white'}
            className="mb-2 head2head"
        >
            <Card.Body>
                <Row className={"players_profile"}>
                    <Col xs={5} className={'player d-flex flex-column align-items-center justify-content-center'}>
                        <div className={"player_profile"}>
                            <img src={playerA?.standing_picture_url? playerA.standing_picture_url : playerA?.picture_url}
                                 className={'rounded-2'}/>
                        </div>
                    </Col>
                    <Col xs={2} className={'text-center fw-bold align-items-start px-0'}>
                        <TournamentType t={match.tournament.type} />
                    </Col>
                    <Col xs={5} className={'player text-center d-flex flex-column align-items-center justify-content-center'}>
                        <div className={"player_profile"}>
                            <img src={playerB?.standing_picture_url? playerB.standing_picture_url : playerB?.picture_url}
                                 className={'rounded-2'}/>
                        </div>
                    </Col>
                </Row>
                <Row className={'mb-5 players_info'}>
                    <Col xs={5} className={'player d-flex flex-column align-items-center justify-content-center'}>
                        <div className={"player_info text-start"}>
                            <div className={'mb-2'}>
                                <ReactCountryFlag countryCode={playerA?.country}/> {playerA?.country}
                            </div>
                            <div>{playerA?.first_name} {playerA?.last_name}</div>
                        </div>
                    </Col>
                    <Col className={'position-relative'}>
                        <img src={'/images/h2h.png'} className={'position-absolute'} style={{
                            marginTop: '-10px',
                            left: '-10px'
                        }} />
                    </Col>
                    <Col xs={5}
                         className={'player text-center d-flex flex-column align-items-center justify-content-center'}>
                        <div className={"player_info text-end"}>
                            <div className={'mb-2'}>
                                <ReactCountryFlag countryCode={playerB?.country}/> {playerB?.country}
                            </div>
                            <div>{playerB?.first_name} {playerB?.last_name}</div>
                        </div>
                    </Col>
                </Row>
                <Row className={'stats'}>
                    <Table hover>
                        <tbody>
                        {stats.map((stat) => {
                            return <tr>
                                <td align={'left'} width={'33%'}>{playerA[stat.value]}</td>
                                <td align={'center'}>{stat.key}</td>
                                <td align={'right'} width={'33%'}>{playerB[stat.value]}</td>
                            </tr>
                        })}
                        </tbody>
                    </Table>
                </Row>
                {/*<Row>*/}
                {/*    <Col className={'p-2 mt-2 text-center fw-bold align-content-center justify-content-center'}>*/}
                {/*        <button className={'h2hdetails'}>Show H2H Details ></button>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </Card.Body>
        </Card>
    )
}

export default Head2Head;
