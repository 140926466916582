export default function Impressum() {

    return (
        <div className={'text-start'}>
            <h2>Adresse und Kontakt</h2>
            <p>Cookies Factory s.r.l.<br/>Via Cosseria 2<br/>00192 Roma, Italy<br/>Firmenbuchnummer: RM - 1374076<br/>Firmenbuchgericht:
                Italian Chamber of Commerce<br/>Umsatzsteuer - Identifikationsnummer: 12424661002<br/>E - Mail: mailto:
                finance@cookies.digital<br/>Web:&nbsp;<a href="https://cookies.digital/">www.cookies.digital</a></p>
            <p>Unsere Kundenbetreuung ist f&uuml;r dich erreichbar unter:</p>
            <p>
                Telefon: 0800 100 336<br/>
                Öffnungszeiten: 8.00-18.00 Uhr<br/>
                E-mail: <a href='mailto:info-at@mobileinfo.biz'>info-at@mobileinfo.biz</a>
                <br/><br/>
                Kontaktieren Sie uns für Unterstützung bei unserem <a href='https://de.mobileinfo.biz'
                                                                      target={'_blank'}>Selfcare-Portal</a>
            </p>
            <h2>&Uuml;ber ATP Golden Pass</h2>
            <p>Tauchen Sie ein in die gesamte Action des weltweiten Tennis mit Golden Pass, einer zentralen Plattform
                f&uuml;r alle ATP-Fans weltweit. Finden Sie Live-Ergebnisse, offizielle Spielpl&auml;ne, Statistiken und
                Ranglisten, Nachrichten und Artikel, t&auml;gliche Highlights und exklusive Videos - alles griffbereit.
                Wir bieten ein bereicherndes Nutzererlebnis mit Live-Statistiken, Quizfragen und Umfragen,
                Echtzeit-Dokumentationen, Turnierinformationen mit Terminen und Spielreihenfolge, Spielerinformationen
                und Statistiken mit Fotos und Live-Ergebnissen vom Schiedsrichterstuhl.</p>
            <h2>&Uuml;ber Cookies Factory s.r.l.</h2>
            <p>Cookies Digital wurde von soliden Akteuren in der Online-Marketing-Welt mit mehr als 10 Jahren Erfahrung
                in der mobilen Monetarisierung gegr&uuml;ndet. Wir sind in allen wichtigen europ&auml;ischen
                M&auml;rkten und in &Uuml;bersee aktiv. Wir arbeiten sowohl mit mobilen Aggregatoren als auch mit
                Werbetreibenden zusammen, um eine langfristige Zusammenarbeit und erfolgreiche Gesch&auml;ftsmodelle
                aufzubauen. Flexibilit&auml;t und Engagement sind die Schl&uuml;sselfaktoren, die uns und unseren
                Partnern helfen zu wachsen! Wir gr&uuml;nden neue Unternehmen, egal wie herausfordernd sie anfangs
                aussehen m&ouml;gen. Sie k&ouml;nnen alles erreichen, was Sie wollen, mit einem Hauch von Cookies.</p>
        </div>
    )
}