import {Col, Row} from "react-bootstrap";
import ArticleCard from "../ArticleCard";
import {useEffect, useState} from "react";

import Slider from "react-slick";

import callAPI from "../../common/api";
import {isMobile} from "react-device-detect";
import {useTranslation} from 'react-i18next';


export default function LatestVideos() {


    const {t} = useTranslation();

    const settings = {
        className: "center",
        infinite: false,
        centerPadding: '0px',
        slidesToShow: isMobile ? 2.2 : 3,
        swipeToSlide: true,
        easing: 'linear',
        arrows: false,
        dots: false,
        afterChange: function (index) {
            console.log(
                `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
            );
        }
    };

    const [data, setData] = useState([{},{}, {}, {}, {}, {}, {}]);
    useEffect(() => {
        if (!data || (data && data.length > 0 && data[0] !== {}))
            callAPI({
                page_index: 1,
                page_size: 8,
                category: 'live|highlights|uncovered|interviews|hot_shots|top_court'
            }, 'getVideos').then((resp) => {
                setData(resp.result);
            })
    }, []);

    return (
        <Row>
            <Col lg={12} className={'px-1 px-md-0'} id={'latestVideos'}>
                {data?.length > 0 && <ArticleCard article={data[0]} />}
            </Col>
            <Col lg={12} className={'row p-0 m-0 mt-md-4 mt-2'}>
                {isMobile ?
                    <div className="slider-container px-1">
                        <Slider {...settings}>
                            {data?.slice(1, 4).map((article) => {
                                return <div key={`article${article.id}`} className={'mb-1 pe-1'}>
                                    <ArticleCard small={true} article={article}/>
                                </div>
                            })}
                        </Slider>
                    </div> : (
                        data?.slice(1, 7).map((article) => {
                            return article?.id && <div key={`article${article.id}`} className={'mb-1 mx-0 px-1 col-lg-4 col-6'}>
                                <ArticleCard small={true} article={article}/>
                            </div>
                        })
                    )
                }
            </Col>
        </Row>
    )
}