import {Image} from "react-bootstrap";

import './style.scss'
import RankingIcon from "../Icons/RankingIcon";
import ReactCountryFlag from "react-country-flag";
import SlidingPane from "react-sliding-pane";
import {useEffect, useState, useTransition} from "react";
import moment from "moment";
import callAPI from "../../common/api";
import {useTranslation} from "react-i18next";

const getCountryISO2 = require("country-iso-3-to-2");


function PlayerCard({tmpPlayer, rank}) {

    if(tmpPlayer.picture_url && tmpPlayer.picture_url.indexOf('/player-headshot/') > 0){
        tmpPlayer.standing_picture_url = tmpPlayer.picture_url.replace('/player-headshot/','/player-gladiator-headshot/');
    }

    const {t} = useTranslation();

    const [player, setPlayer] = useState(tmpPlayer);
    const [fetching, setFetching] = useState(false);

    const [opened, slideProfile] = useState(false);

    const fullName = `${player.first_name} ${player.last_name}`;

    const playerAge = moment().diff(player.birth_date, 'years',false);

    //https://www.atptour.com/-/media/alias/player-gladiator-headshot/S0AG

    const otherInfoDict = [
        {'key': 'Birthplace', 'value': player.birth_city},
        {'key': 'Plays', 'value': player.backhand_style},
        {'key': 'Backhand', 'value': player.handedness},
        {'key': 'Coach', 'value': player.coach}
    ]

    const getPlayer = (id) => {
        setFetching(true)
        callAPI({}, 'getPlayers', `/${id}`).then((resp) => {
            let tmpPlayer = resp.result;
            if(tmpPlayer) {
                if(tmpPlayer.picture_url && tmpPlayer.picture_url.indexOf('/player-headshot/') > 0){
                    tmpPlayer.standing_picture_url = tmpPlayer.picture_url.replace('/player-headshot/','/player-gladiator-headshot/');
                }
                setPlayer(tmpPlayer);
                setFetching(false)
            }
        })
    }


    return (
        <div className={'playerCard pt-3'}>
            <div className={'playerImage'} onClick={() => slideProfile(true)}>
                <Image src={player.standing_picture_url?player.standing_picture_url: player.picture_url} fluid={true}/>
            </div>
            <div className={'playerInfo px-4'} onClick={() => slideProfile(true)}>
                <div className={'ranking text-end'}>
                    <RankingIcon/> {rank}
                </div>
                <div className={'country'}>
                    <ReactCountryFlag className={'flag'} countryCode={getCountryISO2(player.nationality_id)}/> {player.nationality}
                </div>
                <div className={'name'}>
                    {fullName}
                </div>
                <div className={'career text-start'}>
                    {t('singles_career')}
                </div>
                <div className={'quickStats'}>
                    <div className={'stat'}>
                        <h2>{player.age}</h2>
                        <span>{t('player.age')}</span>
                    </div>
                    <div className={'stat'}>
                        <h2>{player.weight_kg} KG</h2>
                        <span>{t('player.height')}</span>
                    </div>
                    <div className={'stat'}>
                        <h2>{player.height_cm} CM</h2>
                        <span>{t('player.weight')}</span>
                    </div>
                </div>
            </div>
            <SlidingPane
                className="playerSlidePanel"
                from={'bottom'}
                isOpen={opened}
                onAfterOpen={() => getPlayer(player.id)}
                onRequestClose={() => {
                    slideProfile(false)
                }}
            >
                <div className={'pageHeader px-4'}>
                    <div className={'leftSection'}>
                        <h2> {t('player_profile')}</h2>
                    </div>
                    <div className={'rightSection'}>
                        <div onClick={() => slideProfile(false)}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.40994 8.00019L15.7099 1.71019C15.8982 1.52188 16.004 1.26649 16.004 1.00019C16.004 0.733884 15.8982 0.478489 15.7099 0.290185C15.5216 0.101882 15.2662 -0.00390625 14.9999 -0.00390625C14.7336 -0.00390625 14.4782 0.101882 14.2899 0.290185L7.99994 6.59019L1.70994 0.290185C1.52164 0.101882 1.26624 -0.00390601 0.999939 -0.00390601C0.733637 -0.00390601 0.478243 0.101882 0.289939 0.290185C0.101635 0.478489 -0.00415253 0.733884 -0.00415254 1.00019C-0.00415254 1.26649 0.101635 1.52188 0.289939 1.71019L6.58994 8.00019L0.289939 14.2902C0.196211 14.3831 0.121816 14.4937 0.0710478 14.6156C0.0202791 14.7375 -0.00585938 14.8682 -0.00585938 15.0002C-0.00585938 15.1322 0.0202791 15.2629 0.0710478 15.3848C0.121816 15.5066 0.196211 15.6172 0.289939 15.7102C0.382902 15.8039 0.493503 15.8783 0.615362 15.9291C0.737221 15.9798 0.867927 16.006 0.999939 16.006C1.13195 16.006 1.26266 15.9798 1.38452 15.9291C1.50638 15.8783 1.61698 15.8039 1.70994 15.7102L7.99994 9.41018L14.2899 15.7102C14.3829 15.8039 14.4935 15.8783 14.6154 15.9291C14.7372 15.9798 14.8679 16.006 14.9999 16.006C15.132 16.006 15.2627 15.9798 15.3845 15.9291C15.5064 15.8783 15.617 15.8039 15.7099 15.7102C15.8037 15.6172 15.8781 15.5066 15.9288 15.3848C15.9796 15.2629 16.0057 15.1322 16.0057 15.0002C16.0057 14.8682 15.9796 14.7375 15.9288 14.6156C15.8781 14.4937 15.8037 14.3831 15.7099 14.2902L9.40994 8.00019Z"
                                    fill="#FFF"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className={'playerHeader px-4'}>
                    <div className={'text-start'}>
                        <h2>{fullName}</h2>
                        <p><ReactCountryFlag countryCode={getCountryISO2(player.nationality_id)} /> {player.nationality}</p>
                    </div>
                    <div className={'playerCardImageWrapper'}>
                        <img src={player.standing_picture_url?player.standing_picture_url: player.picture_url} className={'img-fluid playerImage'} />
                    </div>
                    <div className={'playerStat'}>
                        <div>
                            <p>{t('player.rank')}</p>
                            <h4>{rank}</h4>
                            <p>({t('singles')})</p>
                        </div>
                        <div>
                            <p>{t('player.age')}</p>
                            <h4>{player.age? player.age : playerAge}</h4>
                            <p>({t('singles')})</p>
                        </div>
                        <div>
                            <p>{t('player.height')}</p>
                            <h4>{player.height_cm} cm</h4>
                            <p>({t('singles')})</p>
                        </div>
                        <div>
                            <p>{t('player.weight')}</p>
                            <h4>{player.weight_kg} Kg</h4>
                            <p>({t('singles_career')})</p>
                        </div>
                    </div>
                </div>
                <div className={'playerOtherInfo'}>
                    {otherInfoDict.map((info) => {
                        return <div className={'playerInfo'} key={`otherInfo${info.key}`}>
                            <span className={'title'}>{t(`player.${info.key.toLowerCase()}`)}</span>
                            <span className={'value'}>{info.value}</span>
                        </div>
                    })}
                </div>
            </SlidingPane>
        </div>
    )
}

export default PlayerCard;
