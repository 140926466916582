import {Col, Container, Row} from "react-bootstrap";
import RankingIcon from "../../components/Icons/RankingIcon";
import MediaIcon from "../../components/Icons/MediaIcon";
import LatestVideos from "../../components/LatestVideos";
import {useEffect, useState} from "react";
import callAPI from "../../common/api";
import ArticleCard from "../../components/ArticleCard";
import PlaySquareIcon from "../../components/Icons/PlaySquareIcon";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Media(props) {

    const { t } = useTranslation();

    const {slug} = useParams();

    const [article, setArticle] = useState(null);

    const [data, setData] = useState([{}, {}, {}, {}, {}, {}, {}]);
    useEffect(() => {
        if (!data || (data && data.length > 0 && data[0] !== {}))
            callAPI({
                page_index: 1,
                page_size: 100,
                category: 'live|highlights|uncovered|interviews|hot_shots|top_court'
            }, 'getVideos').then((resp) => {
                setData(resp.result);
            })
    }, []);

    useEffect(() => {
        if(data){
            if(slug){
                const res = data.filter((a) => a.id === slug);
                if(res.length > 0){
                    setArticle(res[0]);
                }
            }else{
                setArticle(data[0])
            }
        }
    }, [data]);


    return (
        <Container>
            <Row>
                <div className={'pageHeader'}>
                    <div className={'leftSection'}>
                        <MediaIcon/> <h2>{t('menu.media')}</h2>
                    </div>
                    <div className={'rightSection'}>

                    </div>
                </div>
            </Row>
            <Row className={'my-3'}>
                <Col md={12} className={'equal-height-col px-1 px-md-0'} id={'mediaPage'}>
                    {data?.length > 0 && <ArticleCard article={article}  player={true}/>}
                </Col>

                <Col md={12}>
                    <div className={'d-flex flex-row align-items-center justify-content-between'}>
                        <h1 className={'homePageTitle'}>{t('titles.videos')}</h1>
                    </div>
                    <Row>
                    {data?.map((otherArticle) => {
                        return otherArticle === article ? null : <Col md={4} className={'my-1 pe-1'}>
                            <ArticleCard view={'list'} small={true} article={otherArticle} />
                        </Col>
                    })}
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}